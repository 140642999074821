import { QueryConfig } from "react-query";
import { IRequirementResponse } from "@wingspanhq/payments/dist/interfaces";

import { useWSQuery } from "../../helpers";
import { QUERY_SIGNED_DOCUMENT_TEMPLATE } from "../keys";
import { getRequirement } from "../../../services/eligibilityRequirements/getRequirement";
import { getSignedDocumentTemplate } from "../../../services/signedDocuments";
import { ISignedDocumentTemplateResponse } from "@wingspanhq/signed-documents/dist/lib/interfaces";

export const useSignedDocumentTemplateQuery = (
  id: string,
  queryConfig?: QueryConfig<ISignedDocumentTemplateResponse, unknown>
) =>
  useWSQuery(
    [QUERY_SIGNED_DOCUMENT_TEMPLATE, id],
    () => getSignedDocumentTemplate(id),
    {
      retry: false,
      ...queryConfig
    }
  );
