import {
  WSActions,
  WSControl,
  WSElement,
  WSForm,
  WSInfoBox,
  WSList,
  WSPanel,
  WSSectionToolbar,
  WSText
} from "@wingspanhq/fe-component-library";
import * as Yup from "yup";
import { WSErrorMessage } from "../../../../components/WSErrorMessage/WSErrorMessage";
import { QUERY_INTERNAL_ACCOUNT } from "../../../../query/banking/queries/useQueryInternalAccount";
import { QUERY_INTERNAL_ACCOUNTS } from "../../../../query/banking/queries/useQueryInternalAccounts";
import { useWSMutation } from "../../../../query/helpers";
import { QUERY_ACKNOWLEDGEMENT } from "../../../../query/onboarding/queries/useQueryAcknowledgement";
import { createInternalAccount } from "../../../../services/api/banking/internalAccounts";
import {
  bankingAccountAcknowledgements,
  taxWithholdingAccountAcknowledgements
} from "../../../../shared/constants/acknowledgements";
import { updateAcknowledgements } from "../../../../shared/utils/updateAcknowledgements";
import { Acknowledgements } from "../Acknowledgements";

type Props = {
  onBack?: () => void;
  onContinue?: () => void;
};

export const FormActivateTaxWithholdings: React.FC<Props> = ({
  onBack,
  onContinue
}) => {
  const [submit, meta] = useWSMutation(
    async () => {
      await updateAcknowledgements(taxWithholdingAccountAcknowledgements);

      const internalAccount = await createInternalAccount({
        type: "TaxWithholding",
        currency: "USD"
      });

      return internalAccount;
    },
    {
      dependencies: [
        QUERY_INTERNAL_ACCOUNT,
        QUERY_INTERNAL_ACCOUNTS,
        QUERY_ACKNOWLEDGEMENT
      ],
      onSuccess: onContinue
    }
  );

  return (
    <WSForm
      validationSchema={Yup.object().shape({
        agreement: Yup.boolean()
          .nullable()
          .required("Required")
      })}
      onSubmit={submit}
    >
      <WSList gap="2XL">
        <WSPanel>
          <WSList gap="2XL">
            <WSElement>
              <WSSectionToolbar title="Activate Tax Withholding" />
              <WSText.ParagraphSm color="gray500">
                You’ve opted to activate a Tax Withholding. Review and agree to
                all the agreements and terms below to continue.
              </WSText.ParagraphSm>
            </WSElement>

            <WSInfoBox>
              By clicking continue you agree that you have read, understood, and
              agree to the following:
              <br />
              <br />
              <Acknowledgements
                aknowledgements={bankingAccountAcknowledgements}
              />
              <br />
              Wingspan is not a financial services technology company and is not
              a bank. Banking services provided by Lead Bank, member FDIC.
            </WSInfoBox>

            <WSForm.Field
              name="agreement"
              component={WSControl}
              componentProps={{
                type: "checkbox",
                label:
                  "I have read, and agree to all the above-mentioned documents and their contents.",
                size: "S"
              }}
            />

            <WSErrorMessage contextKey="Wallet" error={meta.error} />
          </WSList>
        </WSPanel>

        <WSActions
          alignment="fill"
          buttons={[
            {
              label: "Back",
              kind: "Secondary",
              onClick: onBack,
              visible: !!onBack,
              type: "button"
            },
            {
              label: "Continue",
              loading: meta.isLoading
            }
          ]}
        />
      </WSList>
    </WSForm>
  );
};
