import { useWSSnackbar } from "@wingspanhq/fe-component-library";
import { AccountStatus, IAccount } from "@wingspanhq/users/dist/lib/interfaces";
import { useState } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import plaidIcon from "../../../../assets/images/plaid-icon.png";
import { useUserId } from "../../../../query/hooks/helpers";
import {
  useCreateAccount,
  useUpdateActivity
} from "../../../../query/users/mutations";
import { FormManuallyAddAccount } from "../../../../shared/components/FormManuallyAddAccount";
import {
  avatarBankAccount,
  avatarWallet
} from "../../../../shared/constants/avatars";
import { usePayrollFundingMethodsManagedByOrganization } from "../../../../userFeatures/usePayrollFundingMethodsManagedByOrganization";
import { useMemberPlaidLink } from "../../../../utils/usePlaidLink";
import { selectorAccountDepositReady } from "../../../Transfer/components/FormTransfer/selectorAccountDepositReady";
import { FormActivateWallet } from "../FormActivateWallet";
import { FormSelectAccount } from "../FormSelectAccount";
import { StepOptions } from "../Options";
import { Managed } from "./Managed";
import { PayrollFundingMethodACHType, PayrollFundingMethodType } from "./types";

type Props = { basePath: string; onSuccess?: () => void };

export const FlowAddPayrollFundingMethod: React.FC<Props> = ({
  basePath,
  onSuccess
}) => {
  const userId = useUserId();
  const history = useHistory();
  const isManagedByOrg = usePayrollFundingMethodsManagedByOrganization();
  const [createAccount] = useCreateAccount();
  const [createdAccounts, setCreatedAccounts] = useState<IAccount[]>([]);
  const [updateActivity] = useUpdateActivity(userId, {
    onSuccess
  });

  const handleSuccess = () => {
    updateActivity({
      events: {
        onboardingFundingMethodCompletedAt: new Date()
      }
    });
  };

  const { openSnackbar } = useWSSnackbar();

  const plaidHandler = useMemberPlaidLink({
    onSuccess: async (publicToken: string) => {
      const createResponse = await createAccount({
        publicToken,
        status: AccountStatus.Pending
      });

      let accounts = createResponse
        ? Array.isArray(createResponse)
          ? createResponse
          : [createResponse]
        : [];

      const filteredAccounts = accounts.filter(selectorAccountDepositReady);

      if (filteredAccounts.length > 0) {
        setCreatedAccounts(accounts);

        history.push(basePath + "/accounts");

        return {
          success: true,
          data: accounts
        };
      } else {
        openSnackbar({
          type: "error",
          message: "Account can not be used as a payroll funding method"
        });

        return {
          success: false
        };
      }
    }
  });

  if (isManagedByOrg) {
    return <Managed onContinue={handleSuccess} />;
  }

  return (
    <Switch>
      <Route path={basePath + "/type"}>
        <StepOptions<PayrollFundingMethodType>
          key="type"
          title="Add payroll funding method"
          description="Add a payroll funding method to fund your payrolls and start sending payments"
          options={[
            {
              label: "Bank account (USD)",
              helperText: "Connect your bank account securely",
              value: "ach",
              avatar: avatarBankAccount
            }
            // {
            //   label: "Wingspan Wallet pre-funding (USD)",
            //   helperText:
            //     "Pre-fund and send payments with a free business banking account",
            //   value: "wallet",
            //   avatar: avatarWallet
            // }
          ]}
          onContinue={value => {
            history.push(basePath + "/" + value);
          }}
        />
      </Route>

      <Route path={basePath + "/ach"}>
        <StepOptions<PayrollFundingMethodACHType>
          key="ach"
          title="Add bank account"
          description="Add a bank account as your default payroll funding method"
          options={[
            {
              label: "Link account with Plaid",
              helperText:
                "Instantly verify and connect your bank accounts securely",
              value: "plaid",
              avatar: {
                format: "square",
                type: "image",
                image: plaidIcon
              }
            },
            {
              label: "Set up account manually",
              helperText: "Manually enter your routing and account numbers",
              value: "manual",
              avatar: avatarBankAccount
            }
          ]}
          onBack={() => {
            history.push(basePath + "/type");
          }}
          onContinue={value => {
            if (value === "plaid") {
              plaidHandler.open();
            } else {
              history.push(basePath + "/manual");
            }
          }}
        />
      </Route>

      <Route path={basePath + "/wallet"}>
        <FormActivateWallet
          setFundingSource
          onBack={() => {
            history.push(basePath + "/type");
          }}
          onContinue={handleSuccess}
        />
      </Route>

      <Route path={basePath + "/accounts"}>
        <FormSelectAccount
          title="Select bank account"
          description="Select a bank account as your default payroll funding method"
          setFundingSource
          accounts={createdAccounts}
          onBack={() => {
            history.push(basePath + "/ach");
          }}
          onContinue={handleSuccess}
        />
      </Route>

      <Route path={basePath + "/manual"}>
        <FormManuallyAddAccount
          setFundingSource
          onBack={() => {
            history.push(basePath + "/ach");
          }}
          onSuccess={handleSuccess}
          withPanel
        />
      </Route>

      <Redirect path={basePath} to={basePath + "/type"} />
    </Switch>
  );
};
