import { QueryConfig } from "react-query";
import { IS_LOCAL_ENV } from "../../shared/constants/environment";
import { EnabledExperimentsResponse } from "../../types/experiments";
import { useAnonymousExperiments, useEnabledExperiments } from "../experiment";
import { useWSQuery } from "../helpers";

export const experimentFlags = {
  qboOnboarding: IS_LOCAL_ENV,
  editAllInvoices: IS_LOCAL_ENV,
  nextGenSubscription: IS_LOCAL_ENV,
  collaboratorOnboardingRequirements: IS_LOCAL_ENV,
  purchaseOrderNumber: IS_LOCAL_ENV,
  deduplicateCollaboratorList: IS_LOCAL_ENV,
  physicalCardReplace: IS_LOCAL_ENV,
  payrollGroupsOnTable: IS_LOCAL_ENV,
  electronic1099ConsentRequired: IS_LOCAL_ENV,
  nec1099Filing: IS_LOCAL_ENV,
  giftCards: IS_LOCAL_ENV,
  nec1099EditDetails: IS_LOCAL_ENV,
  nec1099Disputes: IS_LOCAL_ENV,
  payablesDeductions: IS_LOCAL_ENV,
  forceShowQBO: IS_LOCAL_ENV,
  nec1099remail: IS_LOCAL_ENV,
  autopayV1: IS_LOCAL_ENV,
  bulkImporterCollaborator: IS_LOCAL_ENV,
  lineItemCustomFields: IS_LOCAL_ENV,
  meridio: IS_LOCAL_ENV,
  collaboratorGroupsLimit: IS_LOCAL_ENV,
  reports: IS_LOCAL_ENV,
  disableSubscriptionBlocking: IS_LOCAL_ENV,
  improveBulkImportConcurrency: IS_LOCAL_ENV,
  organizationAccounts: IS_LOCAL_ENV,
  nec1099FilingV2: IS_LOCAL_ENV,
  invoicePaymentsDetails: IS_LOCAL_ENV,
  nec1099TaxFormSearch: IS_LOCAL_ENV,
  refundButton: IS_LOCAL_ENV,
  nec1099AddEditRecipient: IS_LOCAL_ENV,
  nec1099TaxFormFilters: IS_LOCAL_ENV,
  nec1099PaymentsUpload: IS_LOCAL_ENV,
  nec1099GenerateAmounts: IS_LOCAL_ENV,
  organizationCollaboratorExternalIdResolution: IS_LOCAL_ENV,
  refundsPane: IS_LOCAL_ENV,
  nec1099CSVDownloads: IS_LOCAL_ENV,
  nec1099AmountFilters: IS_LOCAL_ENV,
  nec1099RecipientsTaxDocuments: IS_LOCAL_ENV,
  nec1099PayerSubmission: IS_LOCAL_ENV,
  nec1099Only: IS_LOCAL_ENV,
  recipientRequestCorrection: IS_LOCAL_ENV,
  accountPause: IS_LOCAL_ENV,
  payableStatus: IS_LOCAL_ENV
};

export const anonExperimentFlags = {
  paymentCards: IS_LOCAL_ENV
};

type ExperimentFlags = typeof experimentFlags;
type AnonExperimentFlags = typeof anonExperimentFlags;

export interface IFeatureFlags extends ExperimentFlags, AnonExperimentFlags {}

const transformExperimentsData = (
  anonymousExperiments: EnabledExperimentsResponse,
  enabledExperiments: EnabledExperimentsResponse,
  defaultExperiments:
    | typeof experimentFlags
    | typeof anonExperimentFlags
    | (typeof experimentFlags & typeof anonExperimentFlags)
) => {
  const anonymousFeatureFlags = anonymousExperiments.reduce<string[]>(
    (result, { featureFlags }) => result.concat(featureFlags),
    []
  );

  const enabledFeatureFlags = enabledExperiments.reduce<string[]>(
    (result, { featureFlags }) => result.concat(featureFlags),
    []
  );

  const allFeatureFlags = [...anonymousFeatureFlags, ...enabledFeatureFlags];

  const newState = Object.keys(defaultExperiments).reduce(
    (acc: any, name: string) => {
      if (allFeatureFlags?.includes(name)) {
        acc[name as any] = true;
      }
      return acc;
    },
    { ...defaultExperiments }
  );

  return { ...newState };
};

export const QUERY_FEATURE_FLAGS = "QUERY_FEATURE_FLAGS";
export const QUERY_ANONYMOUS_FEATURE_FLAGS = "QUERY_ANONYMOUS_FEATURE_FLAGS";

export const useAnonymousFeatureFlags = (
  queryConfig?: QueryConfig<typeof anonExperimentFlags, unknown>
) => {
  const anonymousExperimentsQuery = useAnonymousExperiments();

  return useWSQuery(
    [QUERY_ANONYMOUS_FEATURE_FLAGS, anonymousExperimentsQuery.data],
    async () => {
      const result = transformExperimentsData(
        anonymousExperimentsQuery.data || [],
        [],
        anonExperimentFlags
      );

      return result as typeof anonExperimentFlags;
    },
    {
      ...queryConfig,
      enabled: anonymousExperimentsQuery.data
    }
  );
};

export const useFeatureFlags = (
  queryConfig?: QueryConfig<
    typeof experimentFlags & typeof anonExperimentFlags,
    unknown
  >
) => {
  const anonymousExperimentsQuery = useAnonymousExperiments();
  const enabledExperimentsQuery = useEnabledExperiments();

  return useWSQuery(
    [
      QUERY_FEATURE_FLAGS,
      anonymousExperimentsQuery.data,
      enabledExperimentsQuery.data
    ],
    async () => {
      const result = transformExperimentsData(
        anonymousExperimentsQuery.data || [],
        enabledExperimentsQuery.data || [],
        { ...experimentFlags, ...anonExperimentFlags }
      );

      return result as typeof experimentFlags & typeof anonExperimentFlags;
    },
    {
      ...queryConfig,
      enabled: anonymousExperimentsQuery.data && enabledExperimentsQuery.data
    }
  );
};
