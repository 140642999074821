import { IPayerEngagementResponse } from "@wingspanhq/payments/dist/interfaces";
import { IPayeeEngagementResponse } from "@wingspanhq/payments/dist/interfaces/api/payerPayeeEngagement";
import { RequirementTableItemData } from "../types";

export const selectorRequirementsFromEngagement = (
  engagement: IPayeeEngagementResponse | IPayerEngagementResponse
): RequirementTableItemData[] => {
  return engagement.engagementId
    ? engagement.requirements
    : engagement.eligibilityRequirements;
};
