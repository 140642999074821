import {
  toWSDateString,
  useModalContext,
  WSAvatarIconProps,
  WSCheckboxGroup,
  WSElement,
  WSElementProps,
  WSFiltersOld,
  WSFlexBox,
  WSFormOld,
  WSInfiniteScroll,
  WSInputDateOld,
  WSRadioInputGroup,
  WSSelectOld,
  WSTable,
  WSTableItem,
  WSTableToolbar,
  WSText
} from "@wingspanhq/fe-component-library";
import {
  BulkStatus,
  ClientWorkFlowStatus,
  ICollaboratorSchema,
  InvoiceStatus,
  IPayableSchema,
  IScheduleDate,
  MemberClientStatus,
  MemberWorkFlowStatus
} from "@wingspanhq/payments/dist/interfaces";
import flatten from "lodash/flatten";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import omit from "lodash/omit";
import reduce from "lodash/reduce";
import queryString from "query-string";
import React, { useCallback, useMemo, useState } from "react";
import { InfiniteQueryConfig } from "react-query";
import { Route, useHistory, useLocation } from "react-router-dom";
import { useWSInfiniteQuery } from "../../../query/helpers";
import { useUserId } from "../../../query/hooks/helpers";
import { QUERY_PAYABLES } from "../../../query/payments/keys";
import {
  InvoicesDateRangeFilter,
  useCollaboratorsQuery,
  usePayrollSettings
} from "../../../query/payments/queries";
import {
  getCollaboratorName,
  getIsPayableDisputed,
  getPayables,
  getPayablesByUploadBatchId
} from "../../../query/payments/selectors";
import { WSQueries } from "../../../query/WSQuery";
import {
  InvoiceListFilter,
  PayablesSort,
  PayablesWithSummary,
  paymentsService
} from "../../../services/payments";
import { useAuthorizedScopeGroups } from "../../../shared/utils/teamUtils";
import { openInNewTab } from "../../../utils/openInNewTab";
import { useUrlQueryFilters } from "../../../utils/router";
import { WSServiceError } from "../../../utils/serviceHelper";
import { CompletedDeductionDetails } from "../../screens/deductions/CompletedDuductionDetails";
import { ScheduledDeductionDetails } from "../../screens/deductions/ScheduledDuductionDetails";
import { PayableDetails } from "../../screens/payables/PayableDetails";
import { DateRangeFilter, getDateRangeFilterRanges } from "../../utils";
import { getMatchingPayroll } from "../../utils/payables";
import { BulkApproveModal, PAYABLES_BULK_APPROVE } from "./BulkApproveModal";
import { BulkDeleteModal, PAYABLES_BULK_DELETE } from "./BulkDeleteModal";
import { BulkOpenModal, PAYABLES_BULK_OPEN } from "./BulkOpenModal";
import {
  BulkPreApproveModal,
  PAYABLES_BULK_PREAPPROVE
} from "./BulkPreApproveModal";
import {
  BulkUnapproveModal,
  PAYABLES_BULK_UNAPPROVE
} from "./BulkUnapproveModal";
import styles from "./PayablesTable.module.scss";

import { useBulkPayablesBatchesQuery } from "../../../modules/BulkImporter/query/bulkPayable/queries";
import { getUploadedFilename } from "../../../modules/BulkImporter/utils/getUploadedFilename";
import { useCustomization } from "../../../modules/customization";
import { BulkResource } from "../../../modules/BulkImporter/types";
import { useFeatureFlags } from "../../../query/hooks/useFeatureFlags";
import { getColumns } from "./columns";
import { getNewColumns } from "./newColumns";
import { useDownloadCsv } from "./useDownloadCsv";

export enum PayablesStatusFilter {
  Draft = "Draft",
  ToApprove = "ToApprove",
  Scheduled = "Scheduled",
  PaymentsEligibilityPending = "PaymentsEligibilityPending",
  Paid = "Paid",
  Overdue = "Overdue"
}

const statusFilterToLabel = (statusFilter: PayablesStatusFilter) => {
  if (statusFilter === PayablesStatusFilter.ToApprove) {
    return "Pending Approval";
  }
  if (statusFilter === PayablesStatusFilter.PaymentsEligibilityPending) {
    return "Payments Eligibility Pending";
  }

  return statusFilter;
};

export enum CollaboratorReviewStatusFilter {
  None = "None",
  Accepted = "Accepted",
  Disputed = "Disputed"
}

export interface PayablesFilters {
  status?: PayablesStatusFilter[];

  collaboratorIds?: string[];

  bulkBatchId?: string;

  collaboratorReviewStatus?: CollaboratorReviewStatusFilter[];

  openedAtRange?: DateRangeFilter;
  customOpenedAtRange?: Date[];

  paidAtRange?: DateRangeFilter;
  customPaidAtRange?: Date[];

  payrollRunId?: string;
  payrollRunIdOldStack?: string;

  taxForm1099?: keyof ICollaboratorSchema["form1099Balances"];
  custom?: InvoiceListFilter;
  sort?: PayablesSort;
}

const ALL_WORKFLOW_STATUSES = Object.values(ClientWorkFlowStatus);
const ALL_PAYABLE_STATUSES = Object.values(PayablesStatusFilter);

export const mapPayableFilters = (filters?: PayablesFilters) => {
  let filter: InvoiceListFilter = {
    "labels.invoiceType": {
      "!=": "approvedInvoicesPayment"
    }
  };

  // Status

  const allowedStatuses = new Set<InvoiceStatus>();

  if (
    filters?.status &&
    filters.status.length !== ALL_PAYABLE_STATUSES.length
  ) {
    filters.status.forEach(status => {
      switch (status) {
        case PayablesStatusFilter.Draft:
          allowedStatuses.add(InvoiceStatus.Draft);
          break;
        case PayablesStatusFilter.ToApprove:
        case PayablesStatusFilter.Scheduled:
          allowedStatuses.add(InvoiceStatus.Open);
          allowedStatuses.add(InvoiceStatus.Overdue);
          allowedStatuses.add(InvoiceStatus.Pending);
          break;
        case PayablesStatusFilter.Paid:
          allowedStatuses.add(InvoiceStatus.Paid);
          allowedStatuses.add(InvoiceStatus.PaymentInTransit);
          break;
        case PayablesStatusFilter.Overdue:
          allowedStatuses.add(InvoiceStatus.Overdue);
          break;
        case PayablesStatusFilter.PaymentsEligibilityPending:
          allowedStatuses.add(InvoiceStatus.Pending);
          break;
        default:
      }
    });

    filter.status = {
      in: Array.from(allowedStatuses)
    };

    if (
      filters.status.includes(PayablesStatusFilter.Scheduled) &&
      !filters.status.includes(PayablesStatusFilter.ToApprove)
    ) {
      filter["client.workflowStatus"] = {
        in: [
          ClientWorkFlowStatus.Approved,
          ClientWorkFlowStatus.PaymentInitiated
        ]
      };
    } else if (
      filters.status.includes(PayablesStatusFilter.ToApprove) &&
      !filters.status.includes(PayablesStatusFilter.Scheduled)
    ) {
      filter["client.workflowStatus"] = {
        notIn: [
          ClientWorkFlowStatus.Approved,
          ClientWorkFlowStatus.PaymentInitiated,
          ClientWorkFlowStatus.Funded
        ]
      };
    }

    // If it's only to approve – hide PPL invoices
    if (
      filters.status.length === 1 &&
      filters.status[0] === PayablesStatusFilter.ToApprove
    ) {
      filter["labels.creationSource"] = {
        "!=": "personalPaylink"
      };
    }
  } else {
    filter.status = {
      "!=": InvoiceStatus.Cancelled
    };
  }

  // Collaborator review status

  if (filters?.collaboratorReviewStatus) {
    // Only None
    if (
      filters.collaboratorReviewStatus.length === 1 &&
      filters.collaboratorReviewStatus[0] ===
        CollaboratorReviewStatusFilter.None
    ) {
      filter["member.workflowStatus"] = {
        notIn: [MemberWorkFlowStatus.Accepted, MemberWorkFlowStatus.Disputed]
      };
    }

    // Only Accepted
    if (
      filters.collaboratorReviewStatus.length === 1 &&
      filters.collaboratorReviewStatus[0] ===
        CollaboratorReviewStatusFilter.Accepted
    ) {
      filter["member.workflowStatus"] = MemberWorkFlowStatus.Accepted;
    }

    // Only Disputed
    if (
      filters.collaboratorReviewStatus.length === 1 &&
      filters.collaboratorReviewStatus[0] ===
        CollaboratorReviewStatusFilter.Disputed
    ) {
      filter["member.workflowStatus"] = MemberWorkFlowStatus.Disputed;
    }

    // Accepted and Disputed
    if (
      filters.collaboratorReviewStatus.length === 2 &&
      !filters.collaboratorReviewStatus.includes(
        CollaboratorReviewStatusFilter.None
      )
    ) {
      filter["member.workflowStatus"] = {
        in: [MemberWorkFlowStatus.Accepted, MemberWorkFlowStatus.Disputed]
      };
    }

    // None and Disputed
    if (
      filters.collaboratorReviewStatus.length === 2 &&
      !filters.collaboratorReviewStatus.includes(
        CollaboratorReviewStatusFilter.Accepted
      )
    ) {
      filter["member.workflowStatus"] = {
        "!=": MemberWorkFlowStatus.Accepted
      };
    }

    // None and Accepted
    if (
      filters.collaboratorReviewStatus.length === 2 &&
      !filters.collaboratorReviewStatus.includes(
        CollaboratorReviewStatusFilter.Disputed
      )
    ) {
      filter["member.workflowStatus"] = {
        "!=": MemberWorkFlowStatus.Disputed
      };
    }
  }

  // Collaborator ID
  if (!isEmpty(filters?.collaboratorIds)) {
    filter.memberClientId = {
      in: filters?.collaboratorIds
    };
  }

  // Bulk Batch ID
  if (filters?.bulkBatchId) {
    filter["lineItems.labels.bulkBatchId"] = filters.bulkBatchId;
  }

  // Opened at

  const openedAtRange = getDateRangeFilterRanges(
    filters?.openedAtRange || DateRangeFilter.All,
    filters?.customOpenedAtRange
  );

  if (openedAtRange) {
    filter["events.openedAt"] = {
      ">": openedAtRange[0],
      "<": openedAtRange[1]
    };
  }

  // Paid at

  const paidAtRange = getDateRangeFilterRanges(
    filters?.paidAtRange || DateRangeFilter.All,
    filters?.customPaidAtRange
  );

  if (paidAtRange) {
    filter["events.paidAt"] = {
      ">": paidAtRange[0],
      "<": paidAtRange[1]
    };
  }

  // Payroll run id

  if (filters?.payrollRunId) {
    filter["internal.payroll.payrollId"] = filters.payrollRunId;
  }

  if (filters?.payrollRunIdOldStack) {
    filter["internal.collaborators.parentInvoiceId"] =
      filters.payrollRunIdOldStack;
  }

  // taxForm1099

  if (filters?.taxForm1099) {
    filter["lineItems.labels.taxForm1099"] = filters?.taxForm1099;
  }

  // Custom

  if (filters?.custom) {
    filter = {
      ...filter,
      ...filters.custom
    };
  }

  return filter;
};

export const usePayablesQuery = (
  filters?: PayablesFilters,
  config?: { size?: number } & InfiniteQueryConfig<
    PayablesWithSummary,
    WSServiceError
  >
) => {
  const { hasPaymentsScope } = useAuthorizedScopeGroups();

  const size = config?.size || 100;

  const query = useWSInfiniteQuery<PayablesWithSummary, WSServiceError>(
    [QUERY_PAYABLES, { filters, size }],
    (_, __, pageNumber = 1) => {
      return paymentsService.payable.list({
        filter: mapPayableFilters(filters),
        page: {
          size,
          number: pageNumber
        },
        sort: filters?.sort
          ? filters.sort
          : {
              updatedAt: "desc"
            }
      });
    },
    {
      getFetchMore: (lastPage, allPages) => {
        if (lastPage.data.length < size) {
          return undefined;
        } else {
          return allPages.length + 1;
        }
      },
      enabled: hasPaymentsScope,
      ...config
    }
  );

  return {
    ...query,
    data: query.data
      ? {
          data: flatten(query.data.map(page => page.data)),
          summary: query.data[0]?.summary
        }
      : undefined
  };
};

export type PayablesTableItem = WSTableItem<PayablesTableItemData>;

export type PayablesTableItemData = {
  collaborator?: ICollaboratorSchema;
  payable?: IPayableSchema;
  payrollRun?: IScheduleDate;
};

type PayablesTableProps = {
  title?: string;
  bulkActions?: ("open" | "preapprove" | "approve" | "unapprove" | "delete")[];
  visibleFilters?: {
    status?: boolean;
    collaborator?: boolean;
    bulkBatch?: boolean;
    collaboratorReviewStatus?: boolean;
    openedAtRange?: boolean;
    paidAtRange?: boolean;
  };
  defaultFilters?: PayablesFilters;
  pagination?: {
    limit?: number;
    disableInfiniteScroll?: boolean;
  };
  showInvoiceNumber?: boolean;
  showDateRequested?: boolean;
  showDueDate?: boolean;
  showDatePaid?: boolean;
  showDateExpectedPay?: boolean;
  showHeaders?: boolean;
  showLatestUpdate?: boolean;
  showPayrollGroups?: boolean;
  hideSelectAll?: boolean;
  hideDownloadCsv?: boolean;
  basePath: string;
  emptyState?: EmptyStateProps;
} & WSElementProps;

export const PayablesTable: React.FC<PayablesTableProps> = ({
  title,
  bulkActions,
  defaultFilters,
  showInvoiceNumber,
  showDateRequested,
  showDueDate,
  showDatePaid,
  showDateExpectedPay,
  showHeaders,
  showLatestUpdate,
  showPayrollGroups,
  hideSelectAll,
  hideDownloadCsv,
  basePath,
  pagination,
  visibleFilters,
  emptyState,
  ...elementProps
}) => {
  const userId = useUserId();
  const history = useHistory();
  const location = useLocation();
  const collaboratorsQuery = useCollaboratorsQuery();
  const payrollSettingsQuery = usePayrollSettings(userId);
  const { terminology } = useCustomization();
  const queryFeatureFlags = useFeatureFlags();

  const { openModal } = useModalContext();
  const queries: { uploadBatchId?: string } = queryString.parse(
    location.search
  );
  const defaultFiltersPopulated: PayablesFilters = {
    status: [
      PayablesStatusFilter.Draft,
      PayablesStatusFilter.ToApprove,
      PayablesStatusFilter.Scheduled,
      PayablesStatusFilter.Paid,
      PayablesStatusFilter.Overdue,
      PayablesStatusFilter.PaymentsEligibilityPending
    ],
    collaboratorIds: [],
    collaboratorReviewStatus: [
      CollaboratorReviewStatusFilter.None,
      CollaboratorReviewStatusFilter.Accepted,
      CollaboratorReviewStatusFilter.Disputed
    ],
    openedAtRange: DateRangeFilter.All,
    paidAtRange: DateRangeFilter.All,
    sort: showPayrollGroups
      ? { "internal.events.paymentDueAt": "asc" }
      : undefined,
    ...defaultFilters
  };
  const { filters, setFilters } = useUrlQueryFilters<PayablesFilters>(
    defaultFiltersPopulated
  );
  const payablesQuery = usePayablesQuery(filters, {
    size: pagination?.limit
  });
  const qBulkPayableBatches = useBulkPayablesBatchesQuery();

  const [selection, setSelection] = useState<PayablesTableItem[]>([]);

  const onView = useCallback(
    (item: PayablesTableItem) => {
      if (item.data.payable) {
        history.push({
          pathname: `${basePath}/${item.id}`,
          search: location.search,
          state: {
            backPath: basePath
          }
        });
      }
    },
    [location.search]
  );

  const onViewInNewTab = useCallback(
    (item: PayablesTableItem) => {
      if (item.data.payable) {
        openInNewTab(`${basePath}/${item.id}${location.search}`);
      }
    },
    [location.search]
  );

  const { downloadCsv, isLoading: isDownloadCsvLoading } = useDownloadCsv(
    showPayrollGroups
  );

  const columns = useMemo(
    () =>
      queryFeatureFlags.data?.payableStatus
        ? getNewColumns({
            showDateRequested,
            showDueDate,
            showDatePaid,
            showDateExpectedPay,
            showLatestUpdate,
            payrollSettings: payrollSettingsQuery.data,
            sendPaymentsPaidAtLabel: terminology().sendPaymentsPaidAt,
            filters,
            setFilters
          })
        : getColumns({
            showInvoiceNumber,
            showDateRequested,
            showDueDate,
            showDatePaid,
            showDateExpectedPay,
            showLatestUpdate,
            payrollSettings: payrollSettingsQuery.data,
            sendPaymentsPaidAtLabel: terminology().sendPaymentsPaidAt,
            filters,
            setFilters
          }),
    [
      filters,
      payrollSettingsQuery.data,
      queryFeatureFlags.data?.payableStatus,
      setFilters,
      showDateExpectedPay,
      showDatePaid,
      showDateRequested,
      showDueDate,
      showInvoiceNumber,
      showLatestUpdate,
      terminology
    ]
  );

  return (
    <WSElement {...elementProps}>
      <Route
        path={`${basePath}/:payableId/deduction/scheduled/:deductionId`}
        component={ScheduledDeductionDetails}
      />
      <Route
        path={`${basePath}/:payableId/deduction/completed/:deductionId`}
        component={CompletedDeductionDetails}
      />
      <Route path={`${basePath}/:payableId`} component={PayableDetails} exact />
      <WSQueries
        queries={{
          collaboratorsQuery,
          payablesQuery,
          payrollSettingsQuery,
          qBulkPayableBatches
        }}
      >
        {({
          collaboratorsQuery: { data: collaborators },
          payablesQuery: {
            data: { data: _payables }
          },
          payrollSettingsQuery: { data: payrollSettings },
          qBulkPayableBatches: { data: bulkPayableBatches }
        }) => {
          let payables = getPayables(_payables);

          if (queries.uploadBatchId) {
            payables = getPayablesByUploadBatchId(
              _payables,
              queries?.uploadBatchId
            );
          }

          let data: PayablesTableItem[] = [
            ...payables.map(payable => ({
              id: payable.payableId,
              mark:
                (payable.status === InvoiceStatus.Pending &&
                  payable.client.workflowStatus ===
                    ClientWorkFlowStatus.Approved) ||
                getIsPayableDisputed(payable)
                  ? ("amber400" as any)
                  : undefined,
              data: {
                payable,
                ...(showPayrollGroups || payrollSettings.scheduleDates
                  ? {
                      payrollRun: getMatchingPayroll(payrollSettings, payable)
                    }
                  : {}),
                collaborator: collaborators.find(
                  collaborator =>
                    collaborator.collaboratorId === payable.collaboratorId
                )
              }
            }))
          ];

          const getUniqueCollaborators = (
            collaborators: ICollaboratorSchema[]
          ): ICollaboratorSchema[] => {
            return [
              ...new Map(
                collaborators.map(collaborator => [
                  collaborator.memberId,
                  collaborator
                ])
              ).values()
            ].filter(collaborator => !isEmpty(collaborator));
          };

          const getAssociatedCollaboratorsForSelectedCollaborator = (
            selectedCollaboratorId: string
          ): ICollaboratorSchema[] => {
            const selectedCollaborator = collaborators.find(
              collaborator =>
                collaborator.collaboratorId === selectedCollaboratorId
            );
            return collaborators.filter(
              collaborator =>
                collaborator.memberId === selectedCollaborator?.memberId
            );
          };

          const activeCollaborators = collaborators.filter(
            collaborator => collaborator.status !== MemberClientStatus.Inactive
          );
          const filteredCollaborators = getUniqueCollaborators(
            activeCollaborators
          );

          const filteredBulkBatches = bulkPayableBatches.filter(bulkBatch =>
            [BulkStatus.Complete, BulkStatus.Failed].includes(bulkBatch.status)
          );

          const filtersElement = visibleFilters && (
            <WSFiltersOld
              className={styles.filters}
              filters={filters}
              defaultFilters={defaultFiltersPopulated}
              getCount={(defaultValues, currentValues) => {
                const _defaultValues = omit(defaultValues, ["sort"]);
                const _currentValues = omit(currentValues, ["sort"]);

                return reduce<any, any>(
                  _currentValues,
                  (result, value, key) =>
                    isEqual(value, _defaultValues[key])
                      ? result
                      : result.concat(key),
                  []
                ).length;
              }}
              onFilter={newFilters => {
                const associatedCollaborators = getAssociatedCollaboratorsForSelectedCollaborator(
                  newFilters.collaboratorIds
                );
                const updatedFilters = {
                  ...filters,
                  ...newFilters,
                  collaboratorIds: associatedCollaborators.map(
                    collaborator => collaborator.collaboratorId
                  )
                };

                setFilters(updatedFilters);
              }}
            >
              {visibleFilters.status && (
                <WSFormOld.Field
                  name="status"
                  label="Status"
                  mb="2XL"
                  component={WSCheckboxGroup}
                  componentProps={{
                    options: Object.keys(PayablesStatusFilter).map(key => ({
                      label: statusFilterToLabel(key as PayablesStatusFilter),
                      value: key
                    }))
                  }}
                />
              )}
              {visibleFilters.collaborator && (
                <WSFormOld.Field
                  name="collaboratorIds"
                  label="Contractor"
                  mb="2XL"
                  component={WSSelectOld}
                  componentProps={{
                    searchable: true,
                    placeholder: "All contractors",
                    options: filteredCollaborators.map(collaborator => ({
                      label: getCollaboratorName(collaborator),
                      value: collaborator.collaboratorId,
                      searchText: [
                        collaborator.member.user.email,
                        collaborator.member.user.profile?.firstName,
                        collaborator.member.user.profile?.lastName,
                        collaborator.member.profile?.company?.name,
                        collaborator.member.profile?.company?.legalBusinessName
                      ]
                        .filter(Boolean)
                        .join(" ")
                    }))
                  }}
                />
              )}

              {visibleFilters.bulkBatch && (
                <WSFormOld.Field
                  name="bulkBatchId"
                  label="Bulk Batch"
                  mb="2XL"
                  component={WSSelectOld}
                  componentProps={{
                    searchable: true,
                    placeholder: "All bulk imports",
                    options: filteredBulkBatches.map(bulkBatch => ({
                      label:
                        getUploadedFilename(bulkBatch, BulkResource.Payable) +
                        ` (created ${toWSDateString(bulkBatch.createdAt)})`,
                      value: bulkBatch.bulkPayableBatchId,
                      searchText: [bulkBatch.labels.filename]
                        .filter(Boolean)
                        .join(" ")
                    }))
                  }}
                />
              )}

              {visibleFilters.collaboratorReviewStatus && (
                <WSFormOld.Field
                  name="collaboratorReviewStatus"
                  label="Contractor review status"
                  mb="2XL"
                  component={WSCheckboxGroup}
                  componentProps={{
                    options: Object.keys(CollaboratorReviewStatusFilter).map(
                      key => ({
                        label: key,
                        value: key
                      })
                    )
                  }}
                />
              )}

              {visibleFilters.openedAtRange && (
                <WSFormOld.Field
                  name="openedAtRange"
                  label="Requested date range"
                  mb="2XL"
                  component={WSRadioInputGroup}
                  componentProps={{
                    options: [
                      {
                        label: "All time",
                        value: InvoicesDateRangeFilter.All
                      },
                      {
                        label: "Past week",
                        value: InvoicesDateRangeFilter.PastWeek
                      },
                      {
                        label: "Past month",
                        value: InvoicesDateRangeFilter.PastMonth
                      },
                      {
                        label: "Past year",
                        value: InvoicesDateRangeFilter.PastYear
                      },
                      {
                        label: (
                          <WSFormOld.Context>
                            {({ setValue }) => (
                              <WSFlexBox
                                alignItems="center"
                                className={styles.dateRange}
                              >
                                <WSText mr="M">From</WSText>
                                <WSFormOld.Field
                                  name="customOpenedAtRange"
                                  component={WSInputDateOld}
                                  componentProps={{
                                    selectionMode: "range",
                                    onFocus() {
                                      setValue(
                                        "openedAtRange",
                                        InvoicesDateRangeFilter.Custom
                                      );
                                    }
                                  }}
                                />
                              </WSFlexBox>
                            )}
                          </WSFormOld.Context>
                        ),
                        value: InvoicesDateRangeFilter.Custom
                      }
                    ]
                  }}
                />
              )}

              {visibleFilters.paidAtRange && (
                <WSFormOld.Field
                  name="paidAtRange"
                  label="Paid date range"
                  mb="2XL"
                  component={WSRadioInputGroup}
                  componentProps={{
                    options: [
                      {
                        label: "All time",
                        value: InvoicesDateRangeFilter.All
                      },
                      {
                        label: "Past week",
                        value: InvoicesDateRangeFilter.PastWeek
                      },
                      {
                        label: "Past month",
                        value: InvoicesDateRangeFilter.PastMonth
                      },
                      {
                        label: "Past year",
                        value: InvoicesDateRangeFilter.PastYear
                      },
                      {
                        label: (
                          <WSFormOld.Context>
                            {({ setValue }) => (
                              <WSFlexBox
                                alignItems="center"
                                className={styles.dateRange}
                              >
                                <WSText mr="M">From</WSText>
                                <WSFormOld.Field
                                  name="customPaidAtRange"
                                  component={WSInputDateOld}
                                  componentProps={{
                                    selectionMode: "range",
                                    onFocus() {
                                      setValue(
                                        "paidAtRange",
                                        InvoicesDateRangeFilter.Custom
                                      );
                                    }
                                  }}
                                />
                              </WSFlexBox>
                            )}
                          </WSFormOld.Context>
                        ),
                        value: InvoicesDateRangeFilter.Custom
                      }
                    ]
                  }}
                />
              )}
            </WSFiltersOld>
          );

          return (
            <>
              <WSFlexBox alignItems="center" justify="space-between" mb="XL">
                <WSElement>
                  {title && (
                    <WSText.Heading5 mb={visibleFilters ? "M" : undefined}>
                      {title}
                    </WSText.Heading5>
                  )}
                  {filtersElement}
                </WSElement>
              </WSFlexBox>

              {bulkActions &&
                bulkActions.length > 0 &&
                data.length > 0 &&
                selection.length > 0 && (
                  <>
                    <WSTableToolbar
                      mb="XL"
                      count={selection.length}
                      onClear={() => setSelection([])}
                      actions={bulkActions.map(action => {
                        switch (action) {
                          case "open":
                            return {
                              label: "Open",
                              onClick: () => {
                                openModal(PAYABLES_BULK_OPEN, {
                                  data: selection.map(item => item.data),
                                  onFinish: () => {
                                    setSelection([]);
                                  }
                                });
                              }
                            };
                          case "preapprove":
                            return {
                              label: "Pre-approve",
                              onClick: () => {
                                openModal(PAYABLES_BULK_PREAPPROVE, {
                                  data: selection.map(item => item.data),
                                  onFinish: () => {
                                    setSelection([]);
                                  }
                                });
                              }
                            };
                          case "approve":
                            return {
                              label: "Approve for payment",
                              onClick: () => {
                                openModal(PAYABLES_BULK_APPROVE, {
                                  data: selection.map(item => item.data),
                                  onFinish: () => {
                                    setSelection([]);
                                  }
                                });
                              }
                            };
                          case "unapprove":
                            return {
                              label: "Unapprove",
                              onClick: () => {
                                openModal(PAYABLES_BULK_UNAPPROVE, {
                                  data: selection.map(item => item.data),
                                  onFinish: () => {
                                    setSelection([]);
                                  }
                                });
                              }
                            };
                          case "delete":
                          default:
                            return {
                              label: "Cancel payable",
                              onClick: () => {
                                openModal(PAYABLES_BULK_DELETE, {
                                  data: selection.map(item => item.data),
                                  onFinish: () => {
                                    setSelection([]);
                                  }
                                });
                              }
                            };
                        }
                      })}
                    />
                    <BulkOpenModal />
                    <BulkPreApproveModal />
                    <BulkApproveModal />
                    <BulkUnapproveModal />
                    <BulkDeleteModal />
                  </>
                )}

              <WSInfiniteScroll
                onLoad={() => {
                  payablesQuery.fetchMore();
                }}
                loadMore={
                  !pagination?.disableInfiniteScroll && payables.length > 0
                }
                endOfList={
                  !payablesQuery.canFetchMore ||
                  pagination?.disableInfiniteScroll
                }
                loading={!!payablesQuery.isFetchingMore}
              >
                <WSTable<PayablesTableItemData>
                  tableData={data}
                  headerAction={
                    !hideDownloadCsv
                      ? {
                          icon: "download",
                          onClick: () => {
                            downloadCsv(filters);
                          },
                          loading: isDownloadCsvLoading
                        }
                      : undefined
                  }
                  getGroupName={
                    showPayrollGroups
                      ? item => {
                          if (item.data.payrollRun) {
                            return `${toWSDateString(
                              item.data.payrollRun.date,
                              "monthDate"
                            )} Expected pay date`;
                          }
                          return `Not upcoming payroll`;
                        }
                      : undefined
                  }
                  selection={
                    bulkActions && bulkActions.length > 0
                      ? selection
                      : undefined
                  }
                  onSelectionChange={
                    bulkActions && bulkActions.length > 0
                      ? setSelection
                      : undefined
                  }
                  columns={columns}
                  showHeader={showHeaders}
                  onRowClick={onView}
                  onRowControlClick={onViewInNewTab}
                  onRowMousewheelClick={onViewInNewTab}
                  data-testid="payablesTable"
                />
                {data.length === 0 && emptyState && (
                  <PayablesEmptyState {...emptyState} />
                )}
              </WSInfiniteScroll>
            </>
          );
        }}
      </WSQueries>
    </WSElement>
  );
};

export const buildPayableIcon = (
  payable: IPayableSchema
): Omit<WSAvatarIconProps, "type"> =>
  payable.status === InvoiceStatus.Draft
    ? {
        icon: "edit",
        colorBackground: "gray50",
        color: "gray500"
      }
    : payable.client.workflowStatus === ClientWorkFlowStatus.Approved &&
      (payable.status === InvoiceStatus.Open ||
        payable.status === InvoiceStatus.Overdue)
    ? {
        icon: "calendar",
        colorBackground: "gray50",
        color: "gray500"
      }
    : payable.status === InvoiceStatus.Open
    ? {
        icon: "envelope",
        colorBackground: "blue50",
        color: "blue500"
      }
    : payable.status === InvoiceStatus.Overdue
    ? {
        icon: "envelope",
        colorBackground: "blue50",
        color: "blue500",
        badge: true,
        badgeProps: {
          theme: "red"
        }
      }
    : payable.status === InvoiceStatus.PaymentInTransit
    ? {
        icon: "check",
        colorBackground: "gray50",
        color: "gray500"
      }
    : payable.status === InvoiceStatus.Paid
    ? {
        icon: "check",
        colorBackground: "gray50",
        color: "gray500"
      }
    : payable.status === InvoiceStatus.Pending
    ? {
        icon: "time",
        colorBackground: "gray50",
        color: "gray500",
        badge: true,
        badgeProps: {
          theme: "amber"
        }
      }
    : {
        icon: "edit",
        colorBackground: "gray50",
        color: "gray500"
      };

type EmptyStateProps = {
  firstTime?: string;
  standart: string;
};

export const PayablesEmptyState: React.FC<EmptyStateProps> = ({
  firstTime,
  standart
}) => {
  const payablesQuery = usePayablesQuery({}, { size: 1 });

  return (
    <WSQueries queries={{ payablesQuery }}>
      {({
        payablesQuery: {
          data: { data: payables }
        }
      }) => {
        return (
          <WSFlexBox justify="center" mt="XL">
            <WSText color="gray600">
              {payables.length === 0 && firstTime ? firstTime : standart}
            </WSText>
          </WSFlexBox>
        );
      }}
    </WSQueries>
  );
};
