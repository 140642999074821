import { RequirementType } from "@wingspanhq/payments/dist/interfaces/eligibilityRequirement";

export const REQUIREMENT_TYPE_TO_LABEL: {
  [key in RequirementType]: string;
} = {
  [RequirementType.Signature]: "e-Signature"
};

export const selectorRequirementHumanReadableType = (type: RequirementType) => {
  return REQUIREMENT_TYPE_TO_LABEL[type];
};
