import {
  useModalContext,
  WSButton,
  WSDivider,
  WSElement,
  WSElementProps,
  WSFlexBox,
  WSPanel,
  WSPill,
  WSText
} from "@wingspanhq/fe-component-library";
import { MemberWithholdingStatus } from "@wingspanhq/users/dist/lib/interfaces/member";
import React from "react";
import { useHistory } from "react-router-dom";
import { useUserId } from "../../../query/hooks/helpers";
import { useQueryVerifications } from "../../../query/onboarding/queries/useQueryVerifications";
import { useActivities, useMemberProfile } from "../../../query/users/queries";
import { WSQueries } from "../../../query/WSQuery";
import { selectorIsOnboardingComplete } from "../../../shared/selectors/selectorIsOnboardingComplete";
import { PayoutSplits } from "../../PaymentMethods/components/PayoutSplits";
import { WingspanAccounts } from "../../PaymentMethods/components/WingspanAccounts";
import {
  TaxesModalTurnOnPayment,
  TURN_ON_PAYMENTS
} from "../modals/TaxesModalTurnOnPayment";
import { TaxesModalTurnOnWithholdings } from "../modals/TaxesModalTurnOnWithholdings";

export const Sidebar: React.FC<WSElementProps> = props => {
  const history = useHistory();
  const { openModal } = useModalContext();

  const memberId = useUserId();
  const queryMemberProfile = useMemberProfile(memberId);
  const queryActivity = useActivities(memberId);
  const queryVerifications = useQueryVerifications();

  return (
    <WSQueries
      queries={{
        queryMemberProfile,
        queryActivity
      }}
    >
      {({ queryMemberProfileData, queryActivityData }) => {
        const withholdingTax =
          queryMemberProfileData.profile?.withholdings?.tax;
        const withholdingEnabled =
          withholdingTax?.status === MemberWithholdingStatus.Active;

        const isOnboardingComplete = selectorIsOnboardingComplete(
          queryActivityData,
          queryVerifications.data
        );

        return (
          <WSElement {...props}>
            <TaxesModalTurnOnPayment />
            <TaxesModalTurnOnWithholdings />
            <WSPanel>
              <WSFlexBox alignItems="center">
                {withholdingEnabled ? (
                  <WSPill theme="success" text="Withholdings on" badge />
                ) : (
                  <WSPill theme="warning" text="Withholdings off" badge />
                )}

                <WSButton.Link
                  ml="S"
                  size="S"
                  data-testid="withholdingsSwitcher"
                  onClick={() => {
                    isOnboardingComplete
                      ? openModal("turn-on-withholdings")
                      : openModal(TURN_ON_PAYMENTS);
                  }}
                >
                  {withholdingEnabled ? "Turn off" : "Turn on"}
                </WSButton.Link>
              </WSFlexBox>

              <WSText weight="medium" color="gray600" my="XL">
                My withholding rate is
              </WSText>
              <WSText.Heading1 weight="book" color="gray500" mb="L">
                {withholdingTax?.rate}%
              </WSText.Heading1>
              <WSText.ParagraphSm color="gray500" weight="book">
                The proportion of income set aside so you are financially
                prepared for taxes
              </WSText.ParagraphSm>
              <WSButton.Link
                size="M"
                mt="XL"
                rightIcon="chevron-right"
                onClick={() => history.push("/member/taxes/edit/tax-rate")}
              >
                Update tax profile
              </WSButton.Link>
            </WSPanel>

            <PayoutSplits
              mt="2XL"
              onEditTaxWithholdings={() => {
                isOnboardingComplete
                  ? openModal("turn-on-withholdings")
                  : openModal(TURN_ON_PAYMENTS);
              }}
            />
            <WSDivider my="3XL" />
            <WingspanAccounts mb="XL" />
          </WSElement>
        );
      }}
    </WSQueries>
  );
};
