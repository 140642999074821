import {
  RequirementContextType,
  RequirementTableCallback,
  RequirementTableItemData,
  RequirementTableRowConfig
} from "./types";
import { useSignatureRequirementTableConfig } from "./modules/Signature";
import { RequirementType } from "@wingspanhq/payments/dist/interfaces/eligibilityRequirement";

export const useRequirementTableConfig = (
  contextType: RequirementContextType,
  callback: RequirementTableCallback
) => {
  const signatureConfig = useSignatureRequirementTableConfig(callback);

  const config: RequirementTableRowConfig = {
    [RequirementType.Signature]: signatureConfig
  };

  return {
    renderName(item: RequirementTableItemData) {
      return config[item.requirementType].nameCell[contextType](item);
    },
    renderType(item: RequirementTableItemData) {
      return config[item.requirementType].typeCell[contextType](item);
    },
    renderStatus(item: RequirementTableItemData) {
      return config[item.requirementType].statusCell[contextType](item);
    },
    renderRowActions(item: RequirementTableItemData) {
      return config[item.requirementType].rowActions[contextType](item);
    },
    renderRowMenuActions(item: RequirementTableItemData) {
      return config[item.requirementType].rowMenuActions[contextType](item);
    },
    beforeRender: config[RequirementType.Signature].beforeRender
  };
};
