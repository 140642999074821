import { RouteComponentProps } from "react-router-dom";
import { useEngagementQuery } from "../../../../query/engagements/queries/useEngagementQuery";
import {
  toWSDate,
  WSFlexBox,
  WSGrid,
  WSPage,
  WSPill,
  WSSectionToolbar,
  WSText
} from "@wingspanhq/fe-component-library";
import { WSQueries } from "../../../../query/WSQuery";
import { PageHeader } from "../../../../shared/components/PageHeader";
import { PAYEES_ENGAGEMENTS_LIST_PATH } from "../../utils";
import { EngagementStatus } from "@wingspanhq/payments/dist/interfaces";
import { EngagementInfoPanel } from "../../../Payers/routes/RouteEngagementDetails/EngagementInfoPanel";
import { RequirementsTable } from "./RequirementsTable";
import { useEditEngagementModal } from "../../components/EditEngagementModal";
import { useAddRequirementToEngagementModal } from "../../components/AddRequirementToEngagementModal";
import { RecentContractorsWidget } from "./RecentContractorsWidget";

export const RouteEngagementDetails: React.FC<RouteComponentProps<{
  engagementId: string;
}>> = ({
  match: {
    params: { engagementId }
  },
  history,
  location
}) => {
  const queryEngagement = useEngagementQuery(engagementId);
  const editEngagementModal = useEditEngagementModal();
  const addRequirementToEngagementModal = useAddRequirementToEngagementModal();

  return (
    <WSPage>
      <WSQueries queries={{ queryEngagement }}>
        {({ queryEngagementData: engagement }) => {
          return (
            <>
              <PageHeader
                mb="L"
                title="Clients"
                rootPath={PAYEES_ENGAGEMENTS_LIST_PATH}
                breadcrumbs={[
                  {
                    label: "Engagements",
                    onClick: () => {
                      history.push(PAYEES_ENGAGEMENTS_LIST_PATH);
                    }
                  },
                  {
                    label: engagement.name
                  }
                ]}
                // TODO: render org account name here
                menuItems={[
                  {
                    icon: "edit-box",
                    label: "Edit information",
                    onClick: () => {
                      editEngagementModal.open({ engagement });
                    }
                  },
                  {
                    icon: "clipboard-check",
                    label: "Add requirement",
                    onClick: () => {
                      addRequirementToEngagementModal.open({
                        requirementsIds: engagement.requirementIds,
                        engagementId: engagement.engagementId
                      });
                    }
                  }
                  /*
                {
                  icon: "user",
                  label: "Add contractor",
                  onClick: () => {
                    alert("TODO");
                  }
                },
                // TODO: render separator here
                {
                  icon: "group-fill",
                  label: "Manage team members",
                  onClick: () => {
                    alert("TODO");
                  }
                },
                {
                  icon: "archive",
                  label: "Archive engagement",
                  onClick: () => {
                    alert("TODO");
                  }
                },
                {
                  icon: "trash",
                  label: "Delete engagement",
                  iconProps: {
                    color: "red400"
                  },
                  labelProps: {
                    color: "red400"
                  },
                  onClick: () => {
                    alert("TODO");
                  }
                }
                */
                ]}
              />
              <WSFlexBox.CenterY mb="L" gap="M">
                <WSText.Heading3 singleLine>{engagement.name}</WSText.Heading3>
                {engagement.status === EngagementStatus.Inactive ? (
                  <WSPill theme="neutral" text="Archived" icon={false} />
                ) : engagement.status === EngagementStatus.Active ? (
                  <WSPill theme="success" text="Active" icon={true} />
                ) : null}
                <WSText.ParagraphXs color="gray500">
                  Last modified {toWSDate(engagement.updatedAt).formattedValue}
                </WSText.ParagraphXs>
              </WSFlexBox.CenterY>
              <WSGrid gutter="2XL">
                <WSGrid.Item span={{ m: "8" }}>
                  {/* Engagement information */}
                  <WSSectionToolbar
                    mb="M"
                    title="Engagement information"
                    button={{
                      label: "Edit",
                      onClick: () => {
                        editEngagementModal.open({ engagement });
                      }
                    }}
                  />
                  <EngagementInfoPanel
                    name={engagement.name}
                    description={engagement.description}
                    mb="XL"
                  />

                  {/* Requirements */}
                  <WSSectionToolbar
                    mb="M"
                    title="Requirements"
                    pill={{
                      theme: "neutral",
                      outline: true,
                      text: `${engagement.requirementIds?.length ?? 0}`
                    }}
                    button={{
                      label: "Add requirement",
                      onClick: () => {
                        addRequirementToEngagementModal.open({
                          requirementsIds: engagement.requirementIds,
                          engagementId: engagement.engagementId
                        });
                      }
                    }}
                  />
                  <RequirementsTable engagement={engagement} />
                </WSGrid.Item>

                <WSGrid.Item span={{ m: "4" }}>
                  <RecentContractorsWidget engagementId={engagementId} />
                  {/* 
                  <WSDivider my="XL" />
                  <WSSectionToolbar title="Recent payables" />
                  <RecentPayablesTable payables={[]} /> */}
                </WSGrid.Item>
              </WSGrid>
            </>
          );
        }}
      </WSQueries>
    </WSPage>
  );
};
