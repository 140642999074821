import { IPayerPayeePayerOwnedData } from "@wingspanhq/payments/dist/interfaces";
import {
  ISharedMember,
  ISharedUser
} from "@wingspanhq/users/dist/lib/interfaces";
import { ListRequestQuery } from "../../../utils/serviceHelper";
import { ISearchablePayerPayeeEngagement } from "../payer";
import { serviceSearch } from "../service";
import { PayerPayeeEngagementSearchableStatus } from "../types";
import {
  PayeeTaxDocumentSharePermission,
  PayerPayeeStatus,
  PaymentRequirementStrategy
} from "@wingspanhq/payments/dist/interfaces/payerPayee";

export interface IPayeeRow {
  createdAt: Date;
  updatedAt: Date;
  payeeId: string;
  searchableStatus: PayerPayeeEngagementSearchableStatus;

  payerOwnedData: IPayerPayeePayerOwnedData;
  payeeOwnedData: {
    status: PayerPayeeStatus; //IPayerPayeePayeeOwnedData["status"],
    shareTaxDocument: PayeeTaxDocumentSharePermission; //IPayerPayeePayeeOwnedData["shareTaxDocument"],
    autoPayRequirement: PaymentRequirementStrategy; // IPayerPayeePayeeOwnedData["autoPayRequirement"]
  };

  engagements: ISearchablePayerPayeeEngagement[];
  user: ISharedUser;
  member: ISharedMember;
}

export type PayeeRowsQueryFilter = {
  searchString?: string;
  searchableStatus?:
    | PayerPayeeEngagementSearchableStatus
    | {
        "!="?: PayerPayeeEngagementSearchableStatus;
        in?: PayerPayeeEngagementSearchableStatus[];
      };
  "engagements.engagementId"?:
    | string
    | {
        in?: string[];
      };
  "payerOwnedData.status"?:
    | PayerPayeeStatus
    | {
        "!="?: PayerPayeeStatus;
        in?: PayerPayeeStatus[];
      };
  "payerOwnedData.autoPayStrategy"?:
    | PaymentRequirementStrategy
    | {
        "!="?: PaymentRequirementStrategy;
        in?: PaymentRequirementStrategy[];
      };
  "payeeOwnedData.shareTaxDocument"?:
    | PayeeTaxDocumentSharePermission
    | {
        "!="?: PayeeTaxDocumentSharePermission;
        in?: PayeeTaxDocumentSharePermission[];
      };
  "labels.bulkBatchId"?: {
    in?: string[];
  };
};

export type PayeeRowsQuerySort = {
  updatedAt?: "asc" | "desc";
};

export type PayeeRowsQuery = ListRequestQuery<
  PayeeRowsQueryFilter,
  PayeeRowsQuerySort
>;

export const getPayeeRows = async (
  params?: PayeeRowsQuery
): Promise<IPayeeRow[]> => {
  const response = await serviceSearch.get("/payee-row", {
    params
  });

  return response.data;
};

export type IPayeeSearchRowsSummary = {
  listSize: number;
};

export const getPayeeRowsSummary = async (
  params?: PayeeRowsQuery
): Promise<IPayeeSearchRowsSummary> => {
  const { headers } = await serviceSearch.get(`/payee-row`, {
    params,
    headers: {
      "X-WS-Get-List-Summary": "true"
    }
  });

  return {
    listSize: Number(headers["x-ws-list-size"])
  };
};
