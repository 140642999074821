import { IRequirementDefinitionUpdateRequest } from "@wingspanhq/payments/dist/interfaces";

import { updateRequirement } from "../../../services/eligibilityRequirements/updateRequirement";
import { useWSMutation } from "../../helpers";
import { QUERY_REQUIREMENT, QUERY_REQUIREMENT_LIST } from "../keys";

export const useUpdateRequirement = (id: string) =>
  useWSMutation(
    (payload: IRequirementDefinitionUpdateRequest) =>
      updateRequirement(id, payload),
    {
      dependencies: [[QUERY_REQUIREMENT, id], QUERY_REQUIREMENT_LIST]
    }
  );
