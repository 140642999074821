import {
  WSActions,
  WSDivider,
  WSForm,
  WSList,
  WSPanel,
  WSSectionToolbar,
  WSText
} from "@wingspanhq/fe-component-library";
import { useMemo } from "react";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import {
  components,
  operations
} from "../../../../services/api/onboarding/types";
import { fieldsConfig } from "./fieldsConfig";
import {
  getDefaultValues,
  getValidationSchema,
  renderDisabledUserEmailField,
  renderDisabledUserPhoneField,
  renderField
} from "./helpers";
import { useMutationSubmit } from "./useMutationSubmit";

type RelationshipType = operations["updateCustomerData"]["parameters"]["path"]["relationshipType"];

const getSectionName = (
  entityType: components["schemas"]["CustomerEntity"]["type"],
  sectionName: string
) => {
  switch (sectionName) {
    case "Entity":
      if (entityType === "Business") {
        return "Business Information";
      }

      return "Account Holder (you)";
    case "Representative":
      return "Account Holder (you)";
    case "Owner1":
      return "Owner 1";
    case "Owner2":
      return "Owner 2";
    case "Owner3":
      return "Owner 3";
    case "Owner4":
      return "Owner 4";
    default:
      return;
  }
};

type Props = {
  entityType?: components["schemas"]["CustomerEntity"]["type"];
  requiredFields: string[];
  onCancel?: () => void;
  defaultUserPhoneNumber?: string;
  defaultUserEmail?: string;
};

export const FormMissingData: React.FC<Props> = ({
  entityType,
  defaultUserPhoneNumber,
  defaultUserEmail,
  requiredFields,
  onCancel
}) => {
  const history = useHistory();

  const sections = useMemo(() => {
    const sections: Record<RelationshipType, string[]> = {
      Entity: [],
      Representative: [],
      Owner1: [],
      Owner2: [],
      Owner3: [],
      Owner4: []
    };

    requiredFields.forEach(field => {
      if (!field.includes(".")) {
        sections.Entity.push(field);
        return;
      }

      const [sectionName, fieldName] = field.split(".");

      sections[sectionName as RelationshipType].push(fieldName);
    });

    return sections;
  }, [requiredFields]);

  const validationSchema = useMemo(() => {
    const shape: Record<RelationshipType, Yup.BaseSchema> = {
      Entity: Yup.object(),
      Representative: Yup.object(),
      Owner1: Yup.object(),
      Owner2: Yup.object(),
      Owner3: Yup.object(),
      Owner4: Yup.object()
    };
    Object.entries(sections).forEach(([sectionName, fields]) => {
      shape[sectionName as RelationshipType] = getValidationSchema(fields);
    });

    return Yup.object().shape(shape);
  }, [sections]);

  const [submit, meta] = useMutationSubmit({
    onSuccess: () => {
      history.push("/member/cip/missing-data/pending");
    }
  });

  return (
    <WSForm
      validationSchema={validationSchema}
      onSubmit={submit}
      defaultValues={getDefaultValues(requiredFields, {
        defaultUserPhoneNumber,
        defaultUserEmail
      })}
    >
      <WSList gap="L">
        {Object.entries(sections).map(([sectionName, fields]) => {
          if (fields.length === 0) return null;

          const sectionTitle = getSectionName(entityType, sectionName);

          return (
            <WSPanel key={sectionName}>
              {sectionTitle && <WSSectionToolbar title={sectionTitle} />}
              <WSDivider mb="XL" />
              <WSList gap="L">
                {fields.map(fieldName => {
                  const config = fieldsConfig[fieldName];

                  if (
                    sectionName === "Entity" ||
                    sectionName === "Representative"
                  ) {
                    if (fieldName === "phoneNumber") {
                      return renderDisabledUserPhoneField(
                        sectionName + "." + fieldName,
                        config
                      );
                    }

                    if (fieldName === "email") {
                      return renderDisabledUserEmailField(
                        sectionName + "." + fieldName,
                        config
                      );
                    }
                  }

                  if (!config) return null;
                  return renderField(sectionName + "." + fieldName, config);
                })}
              </WSList>
            </WSPanel>
          );
        })}

        <WSPanel>
          <WSText.ParagraphSm color="gray600">
            At Wingspan, we are required by law to verify your identity. This
            helps us prevent fraud and comply with regulations. Your cooperation
            ensures a safe and secure experience for everyone. Thank you for
            providing the necessary information and documents.
            <br />
            <br />
            The review period typically takes about one (1) business day, during
            this time you may not be able to send/receive payments or access
            banking features.
          </WSText.ParagraphSm>
        </WSPanel>

        <WSActions
          orientation="horizontal"
          alignment="fill"
          buttons={[
            {
              kind: "Secondary",
              label: "Cancel",
              onClick: onCancel,
              visible: !!onCancel
            },
            {
              label: "Continue",
              type: "submit",
              loading: meta.isLoading
            }
          ]}
        />

        <WSText.ParagraphXs color="gray400">
          This form is meant for the recipient listed at the top of this page.
          Please disregard and notify Wingspan immediately if this is not you.
        </WSText.ParagraphXs>
      </WSList>
    </WSForm>
  );
};
