import {
  useIsMobile,
  WSButton,
  WSElement,
  WSFlexBox,
  WSGrid,
  WSLoader,
  WSPage,
  WSPreview,
  WSText
} from "@wingspanhq/fe-component-library";
import { PayrollWorkflowStrategy } from "@wingspanhq/payments/dist/interfaces";
import queryString from "query-string";
import React from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation
} from "react-router-dom";
import image1 from "../../../assets/images/payables-feature-1.png";
import image2 from "../../../assets/images/payables-feature-2.png";
import image3 from "../../../assets/images/payables-feature-3.png";
import image4 from "../../../assets/images/payables-feature-4.png";
import { Actions } from "../../../components/Actions";
import { WSPersistentUpgradeButton } from "../../../components/Membership/WSPersistentUpgradeButton";
import { Tabs } from "../../../components/Tabs";
import { useCustomization } from "../../../modules/customization";
import { PaymentsSetup } from "../../../modules/PaymentsSetup";
import { useUserId } from "../../../query/hooks/helpers";
import { useFeatureFlags } from "../../../query/hooks/useFeatureFlags";
import { useQueryVerifications } from "../../../query/onboarding/queries/useQueryVerifications";
import { usePayrollSettings } from "../../../query/payments/queries";
import { useActivities } from "../../../query/users/queries";
import { WSFrontendFeature } from "../../../Settings/utils/subscriptionUtils";
import { selectorIsOnboardingComplete } from "../../../shared/selectors/selectorIsOnboardingComplete";
import { plural } from "../../../shared/utils/plural";
import { AllDeductions } from "../payableDeductions/AllDeductions";
import { BulkUploadedPayablesById } from "./BulkUploadedPayablesById";
import { CreatePayable } from "./CreatePayable";
import { EditPayable } from "./EditPayable";
import { OffCyclePayroll } from "./OffCyclePayroll";
import { PayablesAll } from "./PayablesAll";
import { PayablesDashboard } from "./PayablesDashboard";
import { PayablesDisputed } from "./PayablesDisputed";
import { PayablesDrafts } from "./PayablesDrafts";
import { PayablesDualStageToApprove } from "./PayablesDualStageToApprove";
import { PayablesDualStageToPreApprove } from "./PayablesDualStageToPreApprove";
import { PayablesPaid } from "./PayablesPaid";
import { PayablesPayrollRuns } from "./PayablesPayrollRuns";
import { PayablesScheduled } from "./PayablesScheduled";
import { PayablesToApprove } from "./PayablesToApprove";
import { PayrollRun } from "./PayrollRun";
import { PayrollSettingsModal } from "./PayrollSettingsModal";

export const Payables: React.FC = () => {
  const { terminology } = useCustomization();
  const userId = useUserId();
  const history = useHistory();
  const location = useLocation();
  const payrollSettingsQuery = usePayrollSettings(userId, { retry: false });
  const featureFlags = useFeatureFlags();
  const isMobile = useIsMobile();
  const queryActivity = useActivities(userId);
  const qeuryVerifications = useQueryVerifications();

  const queries: { uploadBatchId?: string } = queryString.parse(
    location.search
  );

  if (queryActivity.isLoading || payrollSettingsQuery.isLoading) {
    return <WSLoader.Spinner />;
  }

  let tabs = [
    {
      label: "Dashboard",
      path: "/member/invoices/payables/dashboard"
    },
    {
      label: "To Approve",
      path: "/member/invoices/payables/to-approve"
    },
    {
      label: "Scheduled",
      path: "/member/invoices/payables/scheduled"
    },
    {
      label: "Paid",
      path: "/member/invoices/payables/paid"
    },
    {
      label: "Payroll Runs",
      path: "/member/invoices/payables/payroll-runs"
    },
    ...(queries.uploadBatchId
      ? [
          {
            label: "Bulk uploaded",
            path: "/member/invoices/payables/bulk-uploaded",
            params: {
              uploadBatchId: queries.uploadBatchId
            }
          }
        ]
      : []),
    {
      label: "All Payables",
      path: "/member/invoices/payables/all"
    },
    ...(featureFlags.data?.payablesDeductions
      ? [
          {
            label: "All Deductions",
            path: "/member/invoices/payables/deductions"
          }
        ]
      : [])
  ];
  if (
    payrollSettingsQuery.data?.workflow === PayrollWorkflowStrategy.DualStage
  ) {
    tabs = [
      {
        label: "Dashboard",
        path: "/member/invoices/payables/dashboard"
      },
      {
        label: "To Pre-Approve",
        path: "/member/invoices/payables/to-preapprove"
      },
      {
        label: "To Approve",
        path: "/member/invoices/payables/to-approve"
      },
      {
        label: "Scheduled",
        path: "/member/invoices/payables/scheduled"
      },
      {
        label: "Paid",
        path: "/member/invoices/payables/paid"
      },
      {
        label: "Payroll Runs",
        path: "/member/invoices/payables/payroll-runs"
      },
      ...(queries.uploadBatchId
        ? [
            {
              label: "Bulk uploaded",
              path: "/member/invoices/payables/bulk-uploaded",
              params: {
                uploadBatchId: queries.uploadBatchId
              }
            }
          ]
        : []),
      {
        label: "All Payables",
        path: "/member/invoices/payables/all"
      },
      ...(featureFlags.data?.payablesDeductions
        ? [
            {
              label: "All Deductions",
              path: "/member/invoices/payables/deductions"
            }
          ]
        : [])
    ];
  }
  return (
    <Switch>
      <Route
        path="/member/invoices/payables/create"
        component={CreatePayable}
      />

      <Route
        path="/member/invoices/payables/:payableId/edit"
        component={EditPayable}
      />

      <Route
        path="/member/invoices/payables/draft"
        component={PayablesDrafts}
      />

      <Route
        path="/member/invoices/payables/disputed"
        component={PayablesDisputed}
      />

      <Route
        path="/member/invoices/payables/payroll-runs/:payrollRunId"
        component={PayrollRun}
      />

      <Route
        path="/member/invoices/payables/set-up"
        render={() => (
          <PaymentsSetup
            basePath="/member/invoices/payables/set-up"
            onBack={() => {
              history.push("/member/invoices/payables");
            }}
            onFinish={() => {
              history.push("/member/invoices/payables");
            }}
          />
        )}
      />

      <Route
        path="/member/invoices/payables"
        render={() =>
          selectorIsOnboardingComplete(
            queryActivity.data,
            qeuryVerifications.data
          ) ? (
            <WSPage
              title={plural(terminology().sendPaymentsPayable)}
              primaryButtonComponent={
                isMobile
                  ? props => (
                      <WSPersistentUpgradeButton
                        name="createPayable"
                        feature={WSFrontendFeature.InviteAndPayCollaborator}
                        onClick={() => {
                          history.push({
                            pathname: "/member/invoices/payables/create",
                            state: {
                              backPath: location.pathname
                            }
                          });
                        }}
                        {...props}
                      >
                        Create payable
                      </WSPersistentUpgradeButton>
                    )
                  : undefined
              }
              sideContent={
                isMobile ? (
                  undefined
                ) : (
                  <WSFlexBox.CenterY>
                    <WSButton
                      mr="M"
                      name="createPayable"
                      onClick={() => {
                        history.push({
                          pathname: "/member/invoices/payables/create",
                          state: {
                            backPath: location.pathname
                          }
                        });
                      }}
                    >
                      Create payable
                    </WSButton>
                    <Actions
                      name="actions"
                      items={[
                        {
                          label: "Bulk upload payables",
                          onClick() {
                            history.push(
                              "/member/invoices/payables/bulk-upload/info"
                            );
                          }
                        },
                        {
                          label: "Run off-cycle payroll",
                          onClick() {
                            history.push(
                              "/member/invoices/payables/off-cycle-payroll"
                            );
                          }
                        },

                        {
                          label: "Payable settings",
                          onClick() {
                            history.push("/member/settings/billing/payables");
                          }
                        }
                      ]}
                    />
                  </WSFlexBox.CenterY>
                )
              }
            >
              <PayrollSettingsModal />
              <Tabs tabs={tabs} />

              <WSElement mt="2XL" data-testid="payablesContainer">
                <Switch>
                  <Route
                    path="/member/invoices/payables/dashboard"
                    component={PayablesDashboard}
                  />

                  <Route
                    path="/member/invoices/payables/to-preapprove"
                    component={PayablesDualStageToPreApprove}
                  />

                  <Route
                    path="/member/invoices/payables/to-approve"
                    component={
                      payrollSettingsQuery.data?.workflow ===
                      PayrollWorkflowStrategy.DualStage
                        ? PayablesDualStageToApprove
                        : PayablesToApprove
                    }
                  />

                  <Route
                    path="/member/invoices/payables/scheduled"
                    component={PayablesScheduled}
                  />

                  <Route
                    path="/member/invoices/payables/paid"
                    component={PayablesPaid}
                  />

                  <Route
                    path="/member/invoices/payables/payroll-runs"
                    component={PayablesPayrollRuns}
                  />

                  <Route
                    path="/member/invoices/payables/bulk-uploaded"
                    component={BulkUploadedPayablesById}
                  />

                  <Route
                    path="/member/invoices/payables/all"
                    component={PayablesAll}
                  />

                  <Route
                    path="/member/invoices/payables/deductions"
                    component={AllDeductions}
                  />

                  <Route
                    path="/member/invoices/payables/off-cycle-payroll"
                    component={OffCyclePayroll}
                  />

                  <Redirect
                    from="/member/invoices/payables"
                    to="/member/invoices/payables/dashboard"
                  />
                </Switch>
              </WSElement>
            </WSPage>
          ) : (
            <WSPage>
              <WSGrid>
                <WSGrid.Item span={{ l: "5" }}>
                  <WSText.Heading4 mb="M">Payables</WSText.Heading4>
                  <WSText mb={isMobile ? "XL" : "3XL"} color="gray600">
                    Fast, affordable payments for your subcontractors and
                    payroll for your freelance workforce.
                  </WSText>

                  <WSButton
                    onClick={() => {
                      history.push("/member/cip");
                    }}
                    name="setUpPayments"
                  >
                    Set up Payments
                  </WSButton>
                </WSGrid.Item>
              </WSGrid>

              <WSPreview
                mt={isMobile ? "2XL" : "5XL"}
                list={[
                  {
                    title: "Faster payments",
                    description:
                      "Whether you’ve been billed or are creating payables yourself, taking care of payments is no longer a chore.",
                    image: image1,
                    imageWidth: 284
                  },
                  {
                    title: "Scalable payroll",
                    description:
                      "Easily approve and automate payroll cycles. Integrate with your existing accounting software.",
                    image: image2,
                    imageWidth: 395
                  },
                  {
                    title: "Payment notifications",
                    description:
                      "Transparent tracking so you and your team know exactly when funds land in their account.",
                    image: image3,
                    imageWidth: 335
                  },
                  {
                    title: "Taxes and compliance",
                    description:
                      "Wingspan helps with generating 1099s and other tax documents, so you'll be covered come tax time.",
                    image: image4,
                    imageWidth: 396
                  }
                ]}
              />
            </WSPage>
          )
        }
      />
    </Switch>
  );
};
