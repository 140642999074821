import {
  WSActions,
  WSElement,
  WSInfoBox,
  WSList,
  WSPanel,
  WSProgressBar,
  WSSectionToolbar,
  WSText
} from "@wingspanhq/fe-component-library";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { WSErrorMessage } from "../../../../components/WSErrorMessage/WSErrorMessage";
import { ConfirmationDisclosures } from "../../components/ConfirmationDisclosures";
import { ReviewAccountType } from "../../components/ReviewAccountType";
import { ReviewBusinessInformation } from "../../components/ReviewBusinessInformation";
import { ReviewPerson } from "../../components/ReviewPerson";
import { useOnboardignModules, useOnboardingContext } from "../../Context";
import { OnboardingModuleCreateAccount, OnboardingStep } from "../../types";
import { CreateAccountContext } from "./types";
import { useMutationSubmit } from "./useMutationSubmit";
import { useUserId } from "../../../../query/hooks/helpers";
import { useUserProfile } from "../../../../query/users/queries";

export const Review: React.FC<OnboardingStep> = ({ onBack, onNext }) => {
  const [context] = useOnboardingContext<CreateAccountContext>();
  const [submit, meta] = useMutationSubmit({ onSuccess: onNext });
  const [consent, setConsent] = useState(false);
  const history = useHistory();
  const createAcccountPath = history.location.pathname.replace("/review", "");
  const userId = useUserId();
  const queryUser = useUserProfile(userId);

  const modules = useOnboardignModules();
  const onboardingModule = modules.find(m => m.type === "create_account") as
    | OnboardingModuleCreateAccount
    | undefined;

  const includeBeneficialOwners =
    onboardingModule?.options?.verificationLevel === "Banking";

  if (meta.isLoading) {
    return (
      <WSList gap="2XL">
        <WSPanel>
          <WSSectionToolbar title="Verifying your Tax ID" />
          <ProgressBar />
          <WSInfoBox>
            We are verifying your Tax ID. This can take up to 10 seconds.
          </WSInfoBox>
        </WSPanel>

        <WSActions
          alignment="fill"
          buttons={[
            {
              label: "Continue",
              disabled: true
            }
          ]}
        />

        <WSText.ParagraphXs color="gray400">
          The information on this page is required to setup your Wingspan
          account, if you exit or leave without completing this step, you will
          return to this page the next time you sign-in
        </WSText.ParagraphXs>
      </WSList>
    );
  }

  return (
    <WSList gap="2XL">
      <WSList gap="L">
        <WSElement>
          <WSSectionToolbar title="Confirm account information" />
          <WSText.ParagraphSm mt="L" color="gray500">
            Please review to ensure the information is accurate before
            submitting
          </WSText.ParagraphSm>
        </WSElement>

        {context.accountType && (
          <ReviewAccountType
            data={context.accountType}
            onEdit={() => {
              history.push(createAcccountPath + "/account-type");
            }}
          />
        )}

        {context.accountType?.type === "Individual" ? (
          <ReviewPerson
            type="Individual"
            title="Personal information"
            data={context.personalInformation.person}
            showOwnershipStake={includeBeneficialOwners}
            onEdit={() => {
              history.push(createAcccountPath + "/personal-information");
            }}
          />
        ) : (
          <>
            {context.businessInformation && (
              <ReviewBusinessInformation
                data={context.businessInformation}
                country={context.accountType?.country || "US"}
                onEdit={() => {
                  history.push(createAcccountPath + "/business-information");
                }}
                personalEmail={queryUser.data?.email}
              />
            )}

            {context.representative && (
              <ReviewPerson
                type="Representative"
                title="Authorized representative information (You)"
                data={context.representative.representative}
                showOwnershipStake={includeBeneficialOwners}
                onEdit={() => {
                  history.push(createAcccountPath + "/representative");
                }}
              />
            )}

            {includeBeneficialOwners &&
              (context.representative?.beneficialOwners || []).map(
                (beneficialOwner, index) => (
                  <ReviewPerson
                    type="BeneficialOwner"
                    key={String(index)}
                    title={`Beneficial owner (${index + 1})`}
                    data={beneficialOwner}
                    onEdit={() => {
                      history.push(createAcccountPath + "/representative");
                    }}
                    showOwnershipStake
                  />
                )
              )}
          </>
        )}
        <WSPanel>
          <ConfirmationDisclosures
            consent={consent}
            setConsent={setConsent}
            verificationLevel={onboardingModule?.options?.verificationLevel}
          />
        </WSPanel>
      </WSList>

      <WSErrorMessage contextKey="CIP" error={meta.error} />

      <WSActions
        alignment="fill"
        buttons={[
          { label: "Back", onClick: onBack, kind: "Secondary" },
          {
            label: "Confirm & continue",
            disabled: !consent,
            onClick: () => {
              submit();
            }
          }
        ]}
      />

      <WSText.ParagraphXs color="gray400">
        The information on this page is required to setup your Wingspan account,
        if you exit or leave without completing this step, you will return to
        this page the next time you sign-in
      </WSText.ParagraphXs>
    </WSList>
  );
};

const ProgressBar: React.FC = () => {
  const [progress, setProgress] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setProgress(p => {
        if (p >= 80) {
          clearInterval(interval);
        }

        return p + 10;
      });
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return <WSProgressBar percent={progress} />;
};
