import { operations } from "../../../services/api/onboarding/types";
import { AccountType } from "../steps/CreateAccount/AccountType";
import { BusinessInformation } from "../steps/CreateAccount/BusinessInformation";
import { PersonalInformation } from "../steps/CreateAccount/PersonalInformation";
import { Representative } from "../steps/CreateAccount/Representative";
import { Review } from "../steps/CreateAccount/Review";
import { SecureAccount } from "../steps/CreateAccount/SecureAccount";
import {
  OnboardingContext,
  OnboardingModuleCreateAccount,
  OnboardingStep,
  OnboardingStepStatus
} from "../types";

const getStatus = (
  onboardingModule: OnboardingModuleCreateAccount,
  verifications?: operations["getVerifications"]["responses"]["200"]["content"]["application/json"]
): OnboardingStepStatus => {
  const verificationLevel =
    onboardingModule.options?.verificationLevel || "Tax";

  if (verificationLevel === "Tax") {
    if (verifications?.tax && verifications?.tax === "Verified") {
      return "Complete";
    }

    if (verifications?.tax && verifications?.tax !== "None") {
      return "Pending";
    }
  }

  if (verificationLevel === "Banking") {
    if (verifications?.tax && verifications?.banking === "Verified") {
      return "Complete";
    }

    if (verifications?.tax && verifications?.banking !== "None") {
      return "Pending";
    }
  }

  return "None";
};

export const getCreateAccountStepConfig = (
  onboardingModule: OnboardingModuleCreateAccount,
  context: OnboardingContext,
  verifications?: operations["getVerifications"]["responses"]["200"]["content"]["application/json"]
): OnboardingStep => {
  const slug = "create-account";

  const steps: OnboardingStep[] = [];

  steps.push({
    title: "Secure account",
    slug: "secure",
    component: SecureAccount
  });

  steps.push({
    title: "Account type",
    slug: "account-type",
    component: AccountType
  });

  if (context?.accountType?.type === "Individual") {
    steps.push({
      title: "Personal information",
      slug: "personal-information",
      component: PersonalInformation
    });
  } else {
    steps.push({
      title: "Business information",
      slug: "business-information",
      component: BusinessInformation
    });

    steps.push({
      title:
        onboardingModule.options?.verificationLevel === "Banking"
          ? "Representative & beneficial owner(s)"
          : "Representative",
      slug: "representative",
      component: Representative
    });
  }

  steps.push({
    title: "Review & submit",
    slug: "review",
    component: Review
  });

  const status = getStatus(onboardingModule, verifications);

  return {
    title: "Create account",
    slug,
    steps,
    status,
    result: {
      Complete: {
        title: "Your Tax ID was verified",
        description:
          "Your Tax ID was verified. Click continue for the next step."
      },

      Pending: {
        type: "search",
        title: "Awaiting Tax ID verification",
        description:
          "We were not able to verify your Tax ID. We will attempt to verify it again in 24 hours and will reach out if there are any issues. Click continue for the next step."
      }
    }
  };
};
