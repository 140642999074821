import { IRequirement } from "@wingspanhq/payments/dist/interfaces";
import { queryCache } from "react-query";
import { ISignedDocumentResponse } from "@wingspanhq/signed-documents/dist/lib/interfaces";
import { QUERY_SIGNED_DOCUMENT } from "../../../../../../query/signedDocuments/keys";

export const getDocumentFromCache = (requirement: IRequirement) => {
  return queryCache.getQueryData<ISignedDocumentResponse>([
    QUERY_SIGNED_DOCUMENT,
    requirement.dataSourceId
  ]);
};
