import {
  WSElementProps,
  WSGrid,
  WSPanel
} from "@wingspanhq/fe-component-library";

import styles from "./styles.module.scss";
import { InfoItem } from "../../../components/ClientInfoPanel/InfoItem";

export interface EngagementInfoPanelProps extends WSElementProps {
  name: string;
  description?: string;
}

export const EngagementInfoPanel: React.FC<EngagementInfoPanelProps> = ({
  name,
  description,
  ...elementProps
}) => {
  return (
    <WSPanel {...elementProps}>
      <WSGrid className={styles.panel}>
        <WSGrid.Item mb="L" span={{ xs: "12", s: "6" }}>
          <InfoItem label="Engagement name" value={name} />
        </WSGrid.Item>
        {/*
        {type && (
          <WSGrid.Item mb="L" span={{ xs: "12", s: "6" }}>
            <InfoItem
              label="Type"
              value={getEngagementLabelByType(type, { descriptive: true })}
            />
          </WSGrid.Item>
        )}
        */}
        {description && (
          <WSGrid.Item mb="L" span={{ xs: "12" }}>
            <InfoItem
              label="Description"
              value={description}
              singleLine={false}
            />
          </WSGrid.Item>
        )}
      </WSGrid>
    </WSPanel>
  );
};
