import { WSLoader } from "@wingspanhq/fe-component-library";
import { PlatformContextType } from "@wingspanhq/users/dist/lib/interfaces";
import { Redirect } from "react-router-dom";
import { useUserId } from "../../../query/hooks/helpers";
import { useUserProfile } from "../../../query/users/queries";

export const RouteIndex = () => {
  const userId = useUserId();
  const queryUser = useUserProfile(userId);

  if (queryUser.isLoading) {
    return <WSLoader.Spinner size="XS" />;
  }

  if (
    queryUser.data?.contexts?.some(c => c.type === PlatformContextType.Payer)
  ) {
    return <Redirect to="/member/onboarding/payer" />;
  }

  return <Redirect to="/member/onboarding/payee" />;
};
