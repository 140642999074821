import React from "react";
import { PageHeader } from "../../../../shared/components/PageHeader";
import { ENGAGEMENT_REQUIREMENTS_SETTINGS_ROOT_PATH } from "../..";
import { useHistory, useRouteMatch } from "react-router-dom";
import {
  toWSDate,
  WSElement,
  WSFlexBox,
  WSList,
  WSPage,
  WSPanel,
  WSPill,
  WSSectionToolbar,
  WSText
} from "@wingspanhq/fe-component-library";
import { useRequirementQuery } from "../../../../query/eligibilityRequirements/query/useRequirementQuery";
import { WSQueries } from "../../../../query/WSQuery";
import { RequirementDefinitionStatus } from "@wingspanhq/payments/dist/interfaces";
import { useSignedDocumentTemplateQuery } from "../../../../query/signedDocuments/query/useSignedDocumentTemplateQuery";
import { RequirementInformation } from "../../components/RequirementInformation";
import { EngagementsTable } from "./EngagementsTable";
import { helloSignClient, helloSignPromise } from "../../../../utils/helloSign";
import { useRenameRequirementModal } from "../../components/RenameRequirementModal/useRenameRequirementModal";

export const RouteRequirementDetails: React.FC = () => {
  const history = useHistory();
  const match = useRouteMatch<{ requirementId: string }>();
  const { requirementId } = match.params;
  const requirementQuery = useRequirementQuery(requirementId);
  const renameRequirementModal = useRenameRequirementModal();
  const signedDocumentTemplateQuery = useSignedDocumentTemplateQuery(
    requirementQuery.data?.dataSourceId as string,
    {
      enabled: requirementQuery.data?.dataSourceId
    }
  );

  const openPreview = async () => {
    let template = signedDocumentTemplateQuery.data;
    if (!signedDocumentTemplateQuery.isSuccess) return;
    if (!template?.viewUrl) {
      console.error("No HS view URL provided");
      return;
    }

    helloSignClient.open(template.viewUrl);

    await helloSignPromise(); // wait for close
    await signedDocumentTemplateQuery.refetch(); // refetching to enable re-opening the window. view URLs seems to be one-time
  };

  return (
    <WSPage>
      <WSQueries queries={{ requirementQuery, signedDocumentTemplateQuery }}>
        {({ requirementQueryData, signedDocumentTemplateQueryData }) => {
          return (
            <WSElement px="3XL">
              <PageHeader
                title="Requirements"
                rootPath={ENGAGEMENT_REQUIREMENTS_SETTINGS_ROOT_PATH}
                breadcrumbs={[
                  {
                    label: "Requirements",
                    onClick: () => {
                      history.push(ENGAGEMENT_REQUIREMENTS_SETTINGS_ROOT_PATH);
                    }
                  },
                  {
                    label: requirementQueryData.name
                  }
                ]}
                buttons={[
                  {
                    label: "Preview requirement",
                    onClick: () => {
                      openPreview();
                    }
                  }
                ]}
                menuItems={[
                  {
                    icon: "view",
                    label: "Preview requirement",
                    onClick: openPreview
                  },
                  {
                    icon: "edit",
                    label: "Rename requirement",
                    onClick: () =>
                      renameRequirementModal.open({ requirementId })
                  }
                ]}
              />

              <WSFlexBox.CenterY mt="L" mb="2XL" gap="M">
                <WSText.Heading3 singleLine>
                  {requirementQueryData.name}
                </WSText.Heading3>
                {requirementQueryData.status ===
                RequirementDefinitionStatus.Inactive ? (
                  <WSPill theme="neutral" text="Archived" icon={false} />
                ) : requirementQueryData.status ===
                  RequirementDefinitionStatus.Active ? (
                  <WSPill theme="success" text="Ready" icon={false} />
                ) : null}
                <WSText.ParagraphXs color="gray500">
                  Last modified{" "}
                  {toWSDate(requirementQueryData.updatedAt).formattedValue}
                </WSText.ParagraphXs>
              </WSFlexBox.CenterY>

              <WSList gap="2XL">
                <WSElement>
                  <WSSectionToolbar title="Requirement information" />
                  <WSPanel mt="S">
                    <RequirementInformation
                      requirementDefinition={requirementQueryData}
                      singedDocumentTemplate={signedDocumentTemplateQueryData}
                    />
                  </WSPanel>
                </WSElement>

                <WSElement>
                  <WSSectionToolbar mb="S" title="Requirement in engagements" />
                  <EngagementsTable requirementId={requirementId} />
                </WSElement>
              </WSList>
            </WSElement>
          );
        }}
      </WSQueries>
    </WSPage>
  );
};
