import {
  toWSDate,
  useWSSnackbar,
  WSAlert,
  WSElement,
  WSGrid,
  WSList,
  WSPage,
  WSPageToolbar,
  WSPageToolbarAction,
  WSPanel,
  WSSectionToolbar,
  WSText
} from "@wingspanhq/fe-component-library";
import React, { useState } from "react";
import { Route, RouteComponentProps } from "react-router-dom";
import {
  PAYEES_ARCHIVED_PATH,
  PAYEES_DETAILS_ENGAGEMENT_DETAILS_ROUTE,
  PAYEES_LIST_PATH
} from "../../utils";
import { getPayeeNames } from "../../selectors/getPayeeNames";
import { WSQueries } from "../../../../query/WSQuery";
import { usePayeeQuery } from "../../../../query/payee/queries/usePayeeQuery";
import { EligibilityRequirements } from "./EligibilityRequirements";
import { usePayeeEngagementsListAllQuery } from "../../../../query/payeeEngagements/queries/usePayeeEngagementsListAllQuery";
import { EngagementsTable } from "./EngagementsTable";
import { EngagementDetailsSidebar } from "./EngagementDetailsSidebar";
import { UserStatus } from "@wingspanhq/users/dist/lib/interfaces";
import { useEditContractorModal } from "../../components/UpdateContractorModal/useUpdateContractorModal";
import { ContractorInformation } from "../../components/ContractorInformation";
import { CustomFieldsWidget } from "../../components/CustomFieldsWidget/CustomFieldsWidget";
import { RecentPayablesWidget } from "../../components/RecentPayablesWidget";
import { DocumentsSection } from "../../../Payers/components/DocumentsSection";
import { DocumentsSectionType } from "../../../Payers/components/DocumentsSection/types";
import { useUserId } from "../../../../query/hooks/helpers";
import { DeductionsWidget } from "../../components/DeductionWidget";
import { AutopayWidget } from "../../components/AutopayWidget/AutopayWidget";
import { useCreatePayeeEngagementModal } from "../../components/CreatePayeeEngagementModal/useCreateEngagementModal";
import {
  PayeeTaxDocumentSharePermission,
  PayerPayeeStatus
} from "@wingspanhq/payments/dist/interfaces/payerPayee";
import { useArchiveContractorModal } from "../../components/ArchiveContractorModal";
import { useUpdatePayee } from "../../../../query/payee/mutations/useUpdatePayee";
import { PayeeIntegrationWidget } from "../../../Payers/components/IntegrationsSection/PayeeIntegrationWidget";
import { useCollaboratorGroupsQuery } from "../../../../query/payments/queries";
import { PayerPayeeEngagementStatus } from "@wingspanhq/payments/dist/interfaces";
import { useFeatureFlags } from "../../../../query/hooks/useFeatureFlags";
import { useIntegrationsQuickbooks } from "../../../../query/integrations/queries";
import { selectorIsQBOActive } from "../../../../shared/selectors/selectorIsQBOActive";
import { selectorPayeeEngagementsTmp } from "../../selectors/selectorPayeeEngagementsTmp";
import { useAuthorizedScopeGroups } from "../../../../shared/utils/teamUtils";
import { useResendPayeeInvite } from "../../../../query/payee/mutations/useResendPayeeInvite";
import { useAddToGroupModal } from "../../components/AddToGroupModal/useAddToGroupModal";
import { useIsEngagementsActive } from "../../../../shared/hooks/useIsEngagementsActive";
import { selectorPayerPayeeEngagements } from "../../selectors/selectorPayerPayeeEngagements";

export const RoutePayeeDetails: React.FC<RouteComponentProps<{
  payeeId: string;
}>> = ({ match, history, location }) => {
  const { payeeId } = match.params;
  const userId = useUserId();
  const queryPayee = usePayeeQuery(payeeId);
  const queryEngagements = usePayeeEngagementsListAllQuery(payeeId);
  const queryGroups = useCollaboratorGroupsQuery();
  const modalAddEngagement = useCreatePayeeEngagementModal();
  const modalAddToGroup = useAddToGroupModal();
  const isEngagementsActive = useIsEngagementsActive();
  const modalEditContractor = useEditContractorModal();
  const modalArchiveContractor = useArchiveContractorModal();
  const [resendInvite, resendInviteMeta] = useResendPayeeInvite();

  const { hasPaymentsScope } = useAuthorizedScopeGroups();

  const queryQBO = useIntegrationsQuickbooks();
  const queryFeatureFlags = useFeatureFlags({
    enabled: queryQBO.isFetched
  });
  const [updatePayee, updatePayeeMeta] = useUpdatePayee();
  const { openSnackbar } = useWSSnackbar();
  const [hideInfo, setHideInfo] = useState<boolean>(false);

  return (
    <WSPage>
      <Route
        path={PAYEES_DETAILS_ENGAGEMENT_DETAILS_ROUTE}
        component={EngagementDetailsSidebar}
      />
      <WSQueries
        queries={{
          queryPayee,
          queryFeatureFlags,
          queryEngagements,
          queryGroups
        }}
      >
        {({
          queryPayeeData,
          queryEngagementsData,
          queryFeatureFlagsData,
          queryGroupsData
        }) => {
          const names = getPayeeNames(queryPayeeData);

          const isQuickbooksActive = selectorIsQBOActive(
            queryFeatureFlagsData,
            queryQBO.data
          );

          const isInactive =
            queryPayeeData.payerOwnedData.status === PayerPayeeStatus.Inactive;
          const isNotRegistered =
            queryPayeeData.user?.status !== UserStatus.Active;
          const isNotShared =
            queryPayeeData.payeeOwnedData?.shareTaxDocument !==
            PayeeTaxDocumentSharePermission.Allow;

          const forceShowAllEngagements =
            isQuickbooksActive ||
            queryEngagementsData.some(
              eng => eng.status === PayerPayeeEngagementStatus.Inactive
            );

          const filteredEngagements = isEngagementsActive
            ? selectorPayerPayeeEngagements(queryEngagementsData, false, true)
            : selectorPayeeEngagementsTmp(
                queryEngagementsData,
                forceShowAllEngagements,
                false,
                true
              );

          const headerActions: WSPageToolbarAction[] = [
            ...(isInactive || !hasPaymentsScope
              ? []
              : ([
                  {
                    label: "Create payable",
                    icon: "plus",
                    buttonKind:
                      queryPayeeData.user?.status === UserStatus.Pending
                        ? undefined
                        : "Primary",
                    onClick: () => {
                      history.push("/member/invoices/payables/create", {
                        backPath: location.pathname,
                        email: names.email
                      });
                    }
                  }
                ] as const)),
            ...(isInactive
              ? ([
                  {
                    label: "Restore",
                    onAsyncClick: async () => {
                      await updatePayee(
                        {
                          payeeId: queryPayeeData.payeeId,
                          payerOwnedData: {
                            status: PayerPayeeStatus.Active
                          }
                        },
                        {
                          onSuccess: () => {
                            openSnackbar({
                              duration: 5000,
                              type: "success",
                              message: "Contractor restored successfully"
                            });
                          },
                          onError: () => {
                            openSnackbar({
                              duration: 5000,
                              type: "warning",
                              message: `Failed to restore contractor`
                            });
                          }
                        }
                      );
                    },
                    icon: "repeat-circle"
                  }
                ] as const)
              : ([
                  ...(queryPayeeData.user?.status === UserStatus.Pending
                    ? [
                        {
                          label: "Resend invite",
                          buttonKind: "Primary",
                          onAsyncClick: async () => {
                            await resendInvite(queryPayeeData.payeeId);
                          },
                          icon: "send"
                        }
                      ]
                    : []),
                  {
                    label: "Edit contractor",
                    onClick: () => {
                      modalEditContractor.open({
                        payee: queryPayeeData
                      });
                    },
                    icon: "edit"
                  },
                  {
                    label: "Archive contractor",
                    onClick: () => {
                      modalArchiveContractor.open({
                        payee: queryPayeeData,
                        engagements: queryEngagementsData,
                        groups: queryGroupsData
                      });
                    },
                    icon: "archive"
                  },
                  // {
                  //   label: "Delete contractor",
                  //   onClick: () => {
                  //     history.push(`${match.url}/delete`);
                  //   },
                  //   destructive: true,
                  //   icon: "trash"
                  // },
                  ...(isEngagementsActive
                    ? [
                        {
                          label: "Add engagement",
                          onClick: () => {
                            modalAddEngagement.open({
                              payeeId: queryPayeeData.payeeId,
                              engagements: queryEngagementsData
                            });
                          },
                          icon: "plus"
                        }
                      ]
                    : [
                        {
                          label: "Add to group",
                          onClick: () => {
                            modalAddToGroup.open({
                              payeeId: queryPayeeData.payeeId,
                              engagements: queryEngagementsData
                            });
                          },
                          icon: "plus"
                        }
                      ])
                ] as WSPageToolbarAction[]))
          ];

          return (
            <>
              <WSPageToolbar
                breadcrumbs={[
                  {
                    label: "Contractors",
                    onClick: () => {
                      history.push(PAYEES_LIST_PATH);
                    }
                  },
                  ...(queryPayeeData.payerOwnedData.status ===
                  PayerPayeeStatus.Inactive
                    ? [
                        {
                          label: "Archived contractors",
                          onClick: () => {
                            history.push(PAYEES_ARCHIVED_PATH);
                          }
                        }
                      ]
                    : []),
                  { label: names.getResolvedName() }
                ]}
                title={names.getResolvedName()}
                titlePill={
                  queryPayeeData.payerOwnedData.status ===
                  PayerPayeeStatus.Inactive
                    ? {
                        theme: "neutral",
                        text: "Archived",
                        icon: false
                      }
                    : queryPayeeData.user?.status === UserStatus.Active
                    ? { theme: "success", text: "Signed-up", icon: false }
                    : {
                        theme: "warning",
                        text: "Not signed-up",
                        icon: false
                      }
                }
                titleAfter={
                  <WSText.ParagraphXs color="gray500">
                    Last modified{" "}
                    {toWSDate(queryPayeeData.updatedAt).formattedValue}
                  </WSText.ParagraphXs>
                }
                actions={headerActions}
              />
              <WSGrid gutter="XL">
                <WSGrid.Item span={{ m: "8" }}>
                  <WSList gap="XL">
                    {isNotRegistered && !hideInfo && (
                      <WSAlert
                        icon="user"
                        onDismiss={() => {
                          setHideInfo(prev => !prev);
                        }}
                        size="M"
                        theme="info"
                        title="Contractor invited"
                      >
                        <WSText.ParagraphSm weight="book" color="gray700">
                          The contractor has been invited by the email provided
                          to sign-up for a Wingspan account in order to receive
                          payments from you.
                        </WSText.ParagraphSm>
                      </WSAlert>
                    )}
                    {// show if registered but not shared
                    isNotShared && !isNotRegistered && (
                      <WSAlert
                        icon="alert"
                        size="M"
                        theme="warning"
                        title="Tax information not shared"
                      >
                        <WSText.ParagraphSm weight="book" color="gray700">
                          The contractor has not consented to share their tax
                          information with you. Payments to the contractor may
                          not be completed until the information is shared.
                        </WSText.ParagraphSm>
                      </WSAlert>
                    )}
                    <WSElement>
                      <WSSectionToolbar
                        title="Contractor information"
                        button={{
                          label: "Edit",
                          onClick: () => {
                            modalEditContractor.open({
                              payee: queryPayeeData
                            });
                          }
                        }}
                      />
                      <WSPanel mt="S">
                        <ContractorInformation payee={queryPayeeData} />
                      </WSPanel>
                    </WSElement>
                    <EligibilityRequirements
                      payee={queryPayeeData}
                      engagements={queryEngagementsData}
                    />
                    {filteredEngagements.length ? (
                      <WSElement>
                        <WSSectionToolbar
                          title="Engagements"
                          button={
                            isEngagementsActive
                              ? {
                                  label: "Add engagement",
                                  onClick: () => {
                                    modalAddEngagement.open({
                                      payeeId: queryPayeeData.payeeId,
                                      engagements: queryEngagementsData
                                    });
                                  }
                                }
                              : undefined
                          }
                        />
                        <EngagementsTable
                          groups={queryGroupsData}
                          engagements={filteredEngagements}
                          payee={queryPayeeData}
                        />
                      </WSElement>
                    ) : null}
                  </WSList>
                </WSGrid.Item>
                <WSGrid.Item span={{ m: "4" }}>
                  <WSList gap="XL">
                    {hasPaymentsScope ? (
                      <RecentPayablesWidget
                        engagements={queryEngagementsData}
                      />
                    ) : null}

                    <CustomFieldsWidget
                      payee={queryPayeeData}
                      isInactive={isInactive}
                    />

                    <DocumentsSection
                      isInactive={isInactive}
                      payeeId={queryPayeeData.payeeId}
                      payerId={userId}
                      type={DocumentsSectionType.Collaborator}
                    />

                    <DeductionsWidget
                      clientId={userId}
                      memberId={queryPayeeData.payeeId}
                      onRowClick={row => {
                        history.push(
                          `/member/invoices/payables/deductions/scheduled/${row.deductionId}`
                        );
                      }}
                      onShowAllClick={() => {
                        history.push(
                          `/member/invoices/payables/deductions/scheduled`
                        );
                      }}
                    />

                    <AutopayWidget
                      isInactive={isInactive}
                      payee={queryPayeeData}
                    />

                    <PayeeIntegrationWidget
                      isInactive={isInactive}
                      payeeId={queryPayeeData.payeeId}
                      vendorId={
                        queryPayeeData.payerOwnedData.integration?.quickbooks
                          ?.vendorId
                      }
                      expenseAccountId={
                        queryPayeeData.payerOwnedData.integration?.quickbooks
                          ?.expenseAccountId
                      }
                    />
                  </WSList>
                </WSGrid.Item>
              </WSGrid>
            </>
          );
        }}
      </WSQueries>
    </WSPage>
  );
};
