import {
  WSControlGroup,
  WSControlGroupOption,
  WSElement,
  WSForm,
  WSInputDate,
  WSInputMask,
  WSInputNumber,
  WSInputText,
  WSSelect,
  WSSelectOption,
  WSText
} from "@wingspanhq/fe-component-library";
import * as Yup from "yup";
import { US_STATES_OPTIONS } from "../../../../constants/user";
import { SELECT_OPTIONS_CANADIAN_PROVINCES } from "../../../../shared/constants/user";
import { getTaxIdLabel, getTaxIdMask } from "../../helpers/taxId";
import { fieldsConfig } from "./fieldsConfig";
import { Link } from "react-router-dom";

export type FieldConfigText = {
  type: "text";
  label: string;
  requred?: boolean;
  validation?: Yup.BaseSchema;
};

export type FieldConfigNumber = {
  type: "number";
  label: string;
  requred?: boolean;
  validation?: Yup.BaseSchema;
};

export type FieldConfigMask = {
  type: "mask";
  label: string;
  mask: string;
  requred?: boolean;
  validation?: Yup.BaseSchema;
};

export type FieldConfigDate = {
  type: "date";
  label: string;
  requred?: boolean;
  validation?: Yup.BaseSchema;
};

export type FieldConfigRadio = {
  type: "radio";
  label: string;
  options: WSControlGroupOption[];
  requred?: boolean;
  validation?: Yup.BaseSchema;
};

export type FieldConfigSelect = {
  type: "select";
  label: string;
  options: WSSelectOption[];
  requred?: boolean;
  internalSearch?: boolean;
  validation?: Yup.BaseSchema;
};

export type FieldConfigRegion = {
  type: "region";
  label: string;
  requred?: boolean;
  isBusiness?: boolean;
  validation?: Yup.BaseSchema;
};

export type FieldConfigTaxId = {
  type: "taxId";
  label: string;
  requred?: boolean;
  validation?: Yup.BaseSchema;
  isBusiness?: boolean;
};

export type FieldConfig =
  | FieldConfigText
  | FieldConfigNumber
  | FieldConfigMask
  | FieldConfigDate
  | FieldConfigRadio
  | FieldConfigSelect
  | FieldConfigRegion
  | FieldConfigTaxId;

export const renderDisabledUserEmailField = (
  name: string,
  config: FieldConfig
) => {
  return (
    <WSElement>
      <WSForm.Field
        mb="S"
        key={name}
        name={name}
        label={config.label}
        component={WSInputText}
        componentProps={{
          disabled: true,
          placeholder: "Email"
        }}
      />
      <WSForm.Values names={[name]}>
        {values =>
          values[name] ? (
            <WSText.ParagraphXs color="gray500">
              Your account email will be used for this verification. Visit{" "}
              <Link to="/member/settings/account">settings</Link> if you want to
              change it.
            </WSText.ParagraphXs>
          ) : (
            <WSText.ParagraphXs color="red500">
              We were unable to find an email in our records. Please update it
              in <Link to="/member/settings/account">settings</Link> before
              proceeding.
            </WSText.ParagraphXs>
          )
        }
      </WSForm.Values>
    </WSElement>
  );
};

export const renderDisabledUserPhoneField = (
  name: string,
  config: FieldConfig
) => {
  return (
    <WSElement>
      <WSForm.Field
        mb="S"
        key={name}
        name={name}
        label={config.label}
        component={WSInputMask}
        componentProps={{
          mask: "+9 (999) 999-9999",
          placeholder: "Phone number",
          disabled: true
        }}
      />
      <WSForm.Values names={[name]}>
        {values =>
          values[name] ? (
            <WSText.ParagraphXs color="gray500">
              Your account phone number will be used for this verification.
              Visit <Link to="/member/settings/account">settings</Link> if you
              want to change it.
            </WSText.ParagraphXs>
          ) : (
            <WSText.ParagraphXs color="red500">
              We were unable to find a phone number in our records. Please
              update it in <Link to="/member/settings/account">settings</Link>{" "}
              before proceeding.
            </WSText.ParagraphXs>
          )
        }
      </WSForm.Values>
    </WSElement>
  );
};

const renderTextField = (name: string, config: FieldConfigText) => {
  return (
    <WSForm.Field
      key={name}
      name={name}
      label={config.label}
      component={WSInputText}
      componentProps={{ required: config.requred }}
    />
  );
};

const renderNumberField = (name: string, config: FieldConfigNumber) => {
  return (
    <WSForm.Field
      key={name}
      name={name}
      label={config.label}
      component={WSInputNumber}
      componentProps={{ required: config.requred }}
    />
  );
};

const renderMaskField = (name: string, config: FieldConfigMask) => {
  return (
    <WSForm.Field
      key={name}
      name={name}
      label={config.label}
      component={WSInputMask}
      componentProps={{
        required: config.requred,
        mask: config.mask
      }}
    />
  );
};

const renderDateField = (name: string, config: FieldConfigDate) => {
  return (
    <WSForm.Field
      key={name}
      name={name}
      label={config.label}
      component={WSInputDate}
      componentProps={{ required: config.requred }}
    />
  );
};

const renderRadioField = (name: string, config: FieldConfigRadio) => {
  return (
    <WSForm.Field
      key={name}
      name={name}
      label={config.label}
      component={WSControlGroup}
      componentProps={{
        required: config.requred,
        type: "radio",
        options: config.options
      }}
    />
  );
};

const renderSelectField = (name: string, config: FieldConfigSelect) => {
  return (
    <WSForm.Field
      key={name}
      name={name}
      label={config.label}
      component={WSSelect}
      componentProps={{
        required: config.requred,
        options: config.options,
        internalSearch: config.internalSearch
      }}
    />
  );
};

const renderRegionField = (name: string, config: FieldConfigRegion) => {
  const sectionName = name.includes(".") ? name.split(".")[0] : undefined;

  return (
    <WSForm.Value name={sectionName ? sectionName + ".country" : "country"}>
      {country =>
        country === "US" ? (
          <WSForm.Field
            key={"US-" + name}
            name={name}
            label="State/Territory of incorporation"
            component={WSSelect}
            componentProps={{
              required: config.requred,
              internalSearch: true,
              options: US_STATES_OPTIONS
            }}
          />
        ) : country === "CA" ? (
          <WSForm.Field
            key={"CA-" + name}
            name={name}
            label="Province of incorporation"
            component={WSSelect}
            componentProps={{
              required: config.requred,
              internalSearch: true,
              options: SELECT_OPTIONS_CANADIAN_PROVINCES
            }}
          />
        ) : (
          <WSForm.Field
            key={"international-" + name}
            name={name}
            label="State/Territory of incorporation"
            component={WSInputText}
            componentProps={{
              required: config.requred
            }}
          />
        )
      }
    </WSForm.Value>
  );
};

const renderTaxIdField = (name: string, config: FieldConfigTaxId) => {
  const sectionName = name.includes(".") ? name.split(".")[0] : undefined;
  const entityType = config.isBusiness ? "Business" : "Individual";

  return (
    <WSForm.Value name={sectionName ? sectionName + ".country" : "country"}>
      {country =>
        country === "US" || country === "CA" ? (
          <WSForm.Field
            key={country + name}
            name={name}
            label={getTaxIdLabel(entityType, country)}
            component={WSInputMask}
            componentProps={{
              required: config.requred,
              placeholder: getTaxIdMask(entityType, country),
              mask: getTaxIdMask(entityType, country),
              unmask: true
            }}
          />
        ) : (
          <WSForm.Field
            key={country + name}
            name={name}
            label={getTaxIdLabel(entityType, country)}
            component={WSInputText}
            componentProps={{
              required: config.requred
            }}
          />
        )
      }
    </WSForm.Value>
  );
};

export const renderField = (name: string, config: FieldConfig) => {
  switch (config.type) {
    case "number":
      return renderNumberField(name, config);
    case "mask":
      return renderMaskField(name, config);
    case "date":
      return renderDateField(name, config);
    case "radio":
      return renderRadioField(name, config);
    case "select":
      return renderSelectField(name, config);
    case "region":
      return renderRegionField(name, config);
    case "taxId":
      return renderTaxIdField(name, config);
    case "text":
    default:
      return renderTextField(name, config);
  }
};

export const getValidationSchema = (fields: string[]) => {
  const shape: Record<string, Yup.BaseSchema> = {};

  fields.forEach(field => {
    const config = fieldsConfig[field];

    if (!config) {
      return;
    }

    switch (config.type) {
      case "number":
        shape[field] = config.requred
          ? Yup.number()
              .nullable()
              .required("Required")
          : Yup.number().nullable();
        break;
      case "date":
        shape[field] = config.requred
          ? Yup.date()
              .nullable()
              .required("Required")
          : Yup.date().nullable();
        break;
      case "mask":
      case "radio":
      case "select":
      case "region":
      case "taxId":
      case "text":
      default:
        shape[field] = config.requred
          ? Yup.string()
              .nullable()
              .required("Required")
          : Yup.string().nullable();
        break;
    }

    if (config.validation) {
      shape[field] = config.validation;
    }
  });
  return Yup.object().shape(shape);
};

export const getDefaultValues = (
  fields: string[],
  {
    defaultUserPhoneNumber,
    defaultUserEmail
  }: {
    defaultUserPhoneNumber?: string;
    defaultUserEmail?: string;
  }
) => {
  const values: Record<string, unknown> = {};

  fields.forEach(field => {
    if ("Representative.phoneNumber" === field) {
      values[field] = defaultUserPhoneNumber;
    } else if ("phoneNumber" === field) {
      values["Entity.phoneNumber"] = defaultUserPhoneNumber;
    } else if ("Representative.email" === field) {
      values[field] = defaultUserEmail;
    } else if ("email" === field) {
      values["Entity.email"] = defaultUserEmail;
    }
  });

  return values;
};
