import { InfiniteQueryConfig } from "react-query";
import { WSServiceError } from "../../../utils/serviceHelper";
import { useWSInfiniteQuery } from "../../helpers";
import { QUERY_REQUIREMENT_LIST } from "../keys";
import {
  listRequirementDefinitions,
  RequirementDefinitionListResponse
} from "../../../services/eligibilityRequirements/listRequirementDefinitions";

export const useRequirementDefinitionListQuery = (
  params = {} as any,
  config?: InfiniteQueryConfig<
    RequirementDefinitionListResponse,
    WSServiceError
  >
) => {
  const { sort, ...filter } = params;

  return useWSInfiniteQuery<RequirementDefinitionListResponse, WSServiceError>(
    [QUERY_REQUIREMENT_LIST, params],
    async (_, __, pageNumber = 1) => {
      return listRequirementDefinitions({
        filter,
        page: { size: 10, number: pageNumber },
        sort
      });
    },
    {
      getFetchMore: (lastPage, allPages) => {
        if (lastPage.data.length < 10) {
          return undefined;
        }

        return allPages.length + 1;
      },
      retry: false,
      ...config
    }
  );
};
