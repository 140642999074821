import { WSElement, WSTableCell } from "@wingspanhq/fe-component-library";
import {
  RedactedPayeeRowsQuery,
  RedactedPayeeRowsQueryFilter
} from "../../../../query/search/payee/queries/utils";
import { PayerPayeeEngagementSearchableStatus } from "../../../../services/search";
import { useUrlQueryFilters } from "../../../../utils/router";
import { usePayeeRowsSummaryQuery } from "../../../../query/search/payee/queries/usePayeeRowsSummaryQuery";

interface ContractorsCountCellProps {
  engagementId: string;
}
export function ContractorsCountCell({
  engagementId
}: ContractorsCountCellProps) {
  const DEFAULT_FILTERS: RedactedPayeeRowsQueryFilter = {
    searchableStatus: {
      "!=": PayerPayeeEngagementSearchableStatus.Inactive
    },
    "engagements.engagementId": engagementId
  };
  const { filters } = useUrlQueryFilters<RedactedPayeeRowsQuery>({
    filter: DEFAULT_FILTERS,
    sort: {
      updatedAt: "desc"
    }
  });
  const queryPayeeRowsSummary = usePayeeRowsSummaryQuery(filters);
  return queryPayeeRowsSummary.isLoading ? (
    <WSElement shimmer>Loading</WSElement>
  ) : (
    <WSTableCell text={`${queryPayeeRowsSummary.data?.listSize ?? "-"}`} />
  );
}
