import { formatPhone } from "../../../shared/utils/formatPhone";
import { getLast4 } from "../../../shared/utils/getLast4";
import { Section } from "../../CIP/components/Confirmation/Section";
import { employersNumberLabelMap } from "../../CIP/helpers/employersNumber";
import { insdustryLabelMap } from "../../CIP/helpers/industry";
import { taxClassificationLabelMap } from "../../CIP/helpers/taxClassification";
import { getTaxIdLabel } from "../../CIP/helpers/taxId";
import { FormData } from "./FormBusinessInformation";

type Props = {
  data: FormData;
  onEdit?: () => void;
  country: string;
  personalEmail?: string;
};

export const ReviewBusinessInformation: React.FC<Props> = ({
  data,
  onEdit,
  country,
  personalEmail
}) => (
  <Section
    title="Business information"
    onEdit={onEdit}
    data={[
      {
        label: "Legal business name",
        value: data.legalBusinessName,
        fsExclude: true
      },
      {
        label: "Doing business as (DBA)",
        value: data.dba,
        fsExclude: true
      },
      data.taxClassification
        ? {
            label: "Federal tax classification",
            value: taxClassificationLabelMap[data.taxClassification]
          }
        : null,
      {
        label: getTaxIdLabel("Business", country),
        value:
          typeof data.taxId === "string" && data.taxId
            ? `••• ${getLast4(data.taxId)}`
            : data.taxId,
        fsExclude: true
      },
      {
        label: "Industry",
        value: data.industry ? insdustryLabelMap[data.industry] : null
      },
      {
        label: "Business email",
        value: data.usePersonalEmail ? personalEmail : data.email
      },
      {
        label: "Business phone number",
        value: formatPhone(
          `+${data.phone.countryCode}${data.phone.phoneNumber}`
        )
      },
      {
        label: "Business website",
        value: data.noWebsite ? "None" : data.website
      },
      {
        label: "Employees",
        value: employersNumberLabelMap[data.numberOfEmployees]
      },
      {
        label: "Business address",
        value: [
          data.address.addressLine1,
          data.address.addressLine2,
          [data.address.city, data.address.state, data.address.postalCode].join(
            " "
          )
        ]
          .filter(Boolean)
          .join("\n"),
        fsExclude: true
      },
      country === "CA" &&
      data.address.state === "QE" &&
      data.canadaQuebecEnterpriseNumber
        ? {
            label: "Québec Enterprise Number (NEQ)",
            value: data.canadaQuebecEnterpriseNumber,
            fsExclude: true
          }
        : null,
      country === "CA" || country === "US"
        ? {
            label: "State/Territory of incorporation",
            value: data.stateOfIncorporation
          }
        : null,
      country === "CA"
        ? {
            label: "Year of incorporation",
            value: data.yearOfIncorporation
          }
        : null
    ]}
  />
);
