import { INewUser } from "@wingspanhq/users";
import { format } from "date-fns";
import { queryCache } from "react-query";
import { useWSMutation, WSMutationConfig } from "../../../../query/helpers";
import { useUserId } from "../../../../query/hooks/helpers";
import { QUERY_NOTIFICATIONS_NOTIFICATIONS } from "../../../../query/notifications/keys";
import { QUERY_CUSTOMER_ENTITY } from "../../../../query/onboarding/queries/useQueryCustomerEntity";
import { QUERY_VERIFICATION_MISSING_DATA } from "../../../../query/onboarding/queries/useQueryVerificationMissingFields";
import { QUERY_VERIFICATIONS } from "../../../../query/onboarding/queries/useQueryVerifications";
import {
  QUERY_USERS_MEMBER_PROFILE,
  QUERY_USERS_USER_PROFILE
} from "../../../../query/users/keys";
import {
  createCustomerEntity,
  getCustomerEntity,
  updateCustomerData
} from "../../../../services/api/onboarding/customer";
import { getVerificationMissingData } from "../../../../services/api/onboarding/missingFields";
import { operations } from "../../../../services/api/onboarding/types";
import {
  getVerifications,
  performVerification
} from "../../../../services/api/onboarding/verifications";
import { usersService } from "../../../../services/users";
import { bankingAcknowledgements } from "../../../../shared/constants/acknowledgements";
import { updateAcknowledgements } from "../../../../shared/utils/updateAcknowledgements";
import { WSServiceError } from "../../../../utils/serviceHelper";
import { phoneFormDataToE164 } from "../../components/FormPartialPhoneNumber";
import { useOnboardignModules, useOnboardingContext } from "../../Context";
import { OnboardingModuleCreateAccount } from "../../types";
import { CreateAccountContext } from "./types";

export const useMutationSubmit = (
  config?: WSMutationConfig<void, WSServiceError, void>
) => {
  const userId = useUserId();

  const [data] = useOnboardingContext<CreateAccountContext>();

  const modules = useOnboardignModules();
  const onboardingModule = modules.find(m => m.type === "create_account") as
    | OnboardingModuleCreateAccount
    | undefined;

  return useWSMutation(
    async () => {
      if (!data.accountType?.type || !data.accountType?.country) {
        throw new Error("Invalid form data");
      }

      const user = await usersService.user.get(userId);

      // 1. Create customer entity
      try {
        // Check if there is customer entity
        await getCustomerEntity();
      } catch (error) {
        // Create it if it doesn't exist
        await createCustomerEntity({
          country: data.accountType.country,
          type: data.accountType.type
        });
      }

      // 2. Define verification level
      const verificationLevel =
        onboardingModule?.options?.verificationLevel || "Tax";

      // 3. Update customer data
      if (data.accountType.type === "Individual") {
        // 3.1 Individual

        // 3.1.1 Check if email and phone number are missing
        const userData = await getUserEmailAndPhone(
          user,
          false,
          verificationLevel
        );

        // 3.1.2 Update entity data
        await updateCustomerData("Entity", {
          customerData: {
            ...userData,
            firstName: data.personalInformation?.person.firstName || undefined,
            middleName:
              data.personalInformation?.person.middleName || undefined,
            lastName: data.personalInformation?.person.lastName || undefined,
            occupation: data.personalInformation?.person.jobTitle || undefined,
            dateOfBirth: data.personalInformation?.person.birthday
              ? format(data.personalInformation?.person.birthday, "yyyy-MM-dd")
              : undefined,
            country: data.personalInformation?.person.country || undefined,
            individualTaxId:
              data.personalInformation?.person.taxId || undefined,
            addressLine1:
              data.personalInformation?.person.address.addressLine1 ||
              undefined,
            addressLine2:
              data.personalInformation?.person.address.addressLine2 ||
              undefined,
            city: data.personalInformation?.person.address.city || undefined,
            region: data.personalInformation?.person.address.state || undefined,
            postalCode:
              data.personalInformation?.person.address.postalCode || undefined
          }
        });
      } else {
        // 3.1 Business

        // 3.1.1 Update entity data
        await updateCustomerData("Entity", {
          customerData: {
            legalBusinessName:
              data.businessInformation?.legalBusinessName || undefined,
            doingBusinessAs: data.businessInformation?.dba || undefined,
            federalTaxClassification:
              data.businessInformation?.taxClassification || undefined,
            businessTaxId: data.businessInformation?.taxId || undefined,
            industry: data.businessInformation?.industry || undefined,
            email:
              (data.businessInformation?.usePersonalEmail
                ? user.email
                : data.businessInformation?.email) || undefined,
            phoneNumber: data.businessInformation?.phone
              ? phoneFormDataToE164(data.businessInformation?.phone)
              : undefined,
            website: data.businessInformation?.noWebsite
              ? "None"
              : data.businessInformation?.website,
            numberOfEmployees:
              data.businessInformation?.numberOfEmployees || undefined,
            addressLine1:
              data.businessInformation?.address.addressLine1 || undefined,
            addressLine2:
              data.businessInformation?.address.addressLine2 || undefined,
            city: data.businessInformation?.address.city || undefined,
            region: data.businessInformation?.address.state || undefined,
            postalCode:
              data.businessInformation?.address.postalCode || undefined,
            country: data.accountType.country || undefined,
            canadaQuebecEnterpriseNumber:
              data.accountType.country === "CA" &&
              data.businessInformation?.address.state === "QC"
                ? data.businessInformation?.canadaQuebecEnterpriseNumber ||
                  undefined
                : undefined,
            regionOfFormation:
              data.accountType.country === "US" ||
              data.accountType.country === "CA"
                ? data.businessInformation?.stateOfIncorporation || undefined
                : undefined,
            yearOfFormation:
              data.accountType.country === "CA"
                ? data.businessInformation?.yearOfIncorporation || undefined
                : undefined
          }
        });

        // 3.1.2 Get email and phone number for representative if missing
        const userData = await getUserEmailAndPhone(
          user,
          true,
          verificationLevel
        );

        // 3.1.3 Update representative data
        await updateCustomerData("Representative", {
          customerData: {
            ...userData,
            firstName:
              data.representative?.representative.firstName || undefined,
            middleName:
              data.representative?.representative.middleName || undefined,
            lastName: data.representative?.representative.lastName || undefined,
            occupation:
              data.representative?.representative.jobTitle || undefined,
            dateOfBirth: data.representative?.representative.birthday
              ? format(
                  data.representative?.representative.birthday,
                  "yyyy-MM-dd"
                )
              : undefined,
            country: data.representative?.representative.country || undefined,
            individualTaxId:
              data.representative?.representative.taxId || undefined,
            addressLine1:
              data.representative?.representative.address.addressLine1 ||
              undefined,
            addressLine2:
              data.representative?.representative.address.addressLine2 ||
              undefined,
            city: data.representative?.representative.address.city || undefined,
            region:
              data.representative?.representative.address.state || undefined,
            postalCode:
              data.representative?.representative.address.postalCode ||
              undefined
          },
          ownershipStake:
            data.representative?.representative.ownershipStake ?? 0
        });

        // 3.1.3 Update beneficial owners data if provided
        const beneficialOwners = data.representative?.beneficialOwners || [];

        if (beneficialOwners.length > 0) {
          for (let i = 0; i < beneficialOwners.length; i++) {
            const beneficialOwner = beneficialOwners[i];
            await updateCustomerData(`Owner${i + 1}` as any, {
              customerData: {
                firstName: beneficialOwner.firstName || undefined,
                middleName: beneficialOwner.middleName || undefined,
                lastName: beneficialOwner.lastName || undefined,
                occupation: beneficialOwner.jobTitle || undefined,
                dateOfBirth: beneficialOwner.birthday
                  ? format(beneficialOwner.birthday, "yyyy-MM-dd")
                  : undefined,
                email: beneficialOwner.email || undefined,
                phoneNumber: beneficialOwner.phone
                  ? phoneFormDataToE164(beneficialOwner.phone)
                  : undefined,
                country: beneficialOwner.country || undefined,
                individualTaxId: beneficialOwner.taxId || undefined,
                addressLine1: beneficialOwner.address.addressLine1 || undefined,
                addressLine2: beneficialOwner.address.addressLine2 || undefined,
                city: beneficialOwner.address.city || undefined,
                region: beneficialOwner.address.state || undefined,
                postalCode: beneficialOwner.address.postalCode || undefined
              },
              ownershipStake: beneficialOwner.ownershipStake ?? 0
            });
          }
        }
      }

      // 4. Update acknowledgements
      if (verificationLevel === "Banking") {
        await updateAcknowledgements(bankingAcknowledgements);
      }

      // 5. Perform verification
      let verifications = await getVerifications();
      if (
        verifications[
          verificationLevel.toLowerCase() as keyof typeof verifications
        ] === "None"
      ) {
        await performVerification(verificationLevel);
      }
      verifications = await getVerifications();
      queryCache.setQueryData(QUERY_VERIFICATIONS, verifications);
    },
    {
      dependencies: [
        QUERY_CUSTOMER_ENTITY,
        QUERY_VERIFICATIONS,
        QUERY_VERIFICATION_MISSING_DATA,
        QUERY_NOTIFICATIONS_NOTIFICATIONS,
        QUERY_USERS_USER_PROFILE,
        QUERY_USERS_MEMBER_PROFILE
      ],
      ...config
    }
  );
};

async function getUserEmailAndPhone(
  user: INewUser,
  isRepresentative: boolean,
  verificationLevel: operations["getVerificationMissingData"]["parameters"]["path"]["verificationLevel"]
) {
  let missingData;
  try {
    missingData = await getVerificationMissingData(verificationLevel);
  } catch (error) {}

  const shouldSetReprecentativeEmail = missingData?.requiredFields?.includes(
    isRepresentative ? "Representative.email" : "email"
  );
  const shouldSetReprecentativePhoneNumber = missingData?.requiredFields?.includes(
    isRepresentative ? "Representative.phoneNumber" : "phoneNumber"
  );

  const userData: { [key: string]: unknown } = {};
  if (shouldSetReprecentativeEmail || shouldSetReprecentativePhoneNumber) {
    if (shouldSetReprecentativeEmail) {
      userData.email = user.email;
    }

    if (shouldSetReprecentativePhoneNumber) {
      userData.phoneNumber = user.phone.number || undefined;
    }
  }

  return userData;
}
