import { toWSDate, WSGrid } from "@wingspanhq/fe-component-library";
import { IRequirementDefinitionResponse } from "@wingspanhq/payments/dist/interfaces";
import { useMemo } from "react";
import {
  FieldView,
  FieldViewProps
} from "../../../Payees/components/FieldView";
import { ISignedDocumentTemplateResponse } from "@wingspanhq/signed-documents/dist/lib/interfaces";

type Props = {
  requirementDefinition: IRequirementDefinitionResponse;
  singedDocumentTemplate: ISignedDocumentTemplateResponse;
};

export const RequirementInformation: React.FC<Props> = ({
  requirementDefinition,
  singedDocumentTemplate
}) => {
  const fields = useMemo(() => {
    const { editUrlExpiresAt, roles } = singedDocumentTemplate;

    const result: FieldViewProps[] = [
      {
        label: "Required signatures",
        value:
          roles.length === 2
            ? "Both contractors and client signatures required"
            : "Contractor signature required"
      },
      {
        label: "Creation date",
        value: toWSDate(requirementDefinition.createdAt).formattedValue
      },
      {
        label: "Expiration",
        value: "Does not expire"
      },
      {
        label: "Description",
        value: requirementDefinition.description
      }
    ].filter(i => i.value);

    return result;
  }, [singedDocumentTemplate]);

  return (
    <WSGrid gutter="2XL">
      {fields.map(({ label, value }) => (
        <WSGrid.Item key={label} span={{ xs: "12", s: "6", l: "6" }}>
          <FieldView label={label} value={value} />
        </WSGrid.Item>
      ))}
    </WSGrid>
  );
};
