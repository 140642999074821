import { WSButton, WSText, useWSModal } from "@wingspanhq/fe-component-library";
import React from "react";
import { openIntercom } from "../../../../shared/utils/intercom";
import { openInNewTab } from "../../../../shared/utils/openInNewTab";
import { WS_LINKS } from "../../../../types/wsLinks";

export const ModalWhyWeCollectInfo: React.FC = () => (
  <>
    <WSText mb="XL">
      Wingspan collect tax information forms (such as W-9, W-8BEN, or equivalent
      international documents) from contractors and service providers to ensure
      compliance with applicable tax reporting requirements. This enables
      accurate reporting of payments to domestic and international contractors
      or companies to the relevant tax authorities.
    </WSText>
    <WSButton.Link
      mb="XL"
      onClick={() => {
        openInNewTab(WS_LINKS.security);
      }}
    >
      Wingspan is SOC 2 and PCI certified secure.
    </WSButton.Link>
    <WSText>
      Have a question?{" "}
      <WSButton.Link onClick={openIntercom}>Contact support</WSButton.Link>
    </WSText>
  </>
);

export const useModalWhyWeCollectInfo = () =>
  useWSModal(ModalWhyWeCollectInfo, {
    title: "Why do we collect this information?",
    size: "S"
  });
