import { TransferType } from "@wingspanhq/bookkeeping/dist/lib/interfaces";
import {
  toWSDateString,
  toWSMoneyString,
  WSButton,
  WSElement,
  WSFlexBox,
  WSText
} from "@wingspanhq/fe-component-library";
import {
  ICollaboratorSchema,
  InvoiceStatus,
  PayoutPreferences
} from "@wingspanhq/payments/dist/interfaces";
import React, { useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Card } from "../../ClientPayments/components/Card/Card";
import { CreditCardPreview } from "../../ClientPayments/components/CreditCardPreview/CreditCardPreview";
import { DetailsLayout } from "../../components/DetailsLayout/DetailsLayout";
import { PublicAttachmentDownloadLink } from "../../components/PublicAttachmentDownloadLink/PublicAttachmentDownloadLink";
import { UrlIdKey } from "../../constants/routing";
import { useUserId } from "../../query/hooks/helpers";
import { useFeatureFlags } from "../../query/hooks/useFeatureFlags";
import {
  useDownloadInvoicePdf,
  useSendInvoice,
  useSendInvoiceReminder
} from "../../query/payments/mutations";
import {
  useCollaboratorsQuery,
  useInvoiceQuery,
  useMemberClientQuery,
  usePayoutSettings
} from "../../query/payments/queries";
import {
  getClientCompany,
  getClientName,
  getCollaboratorName,
  getInvoicePayoutDestinationDescription,
  getIsInstantPayoutAllowed
} from "../../query/payments/selectors";
import { useMemberProfile, useUserProfile } from "../../query/users/queries";
import { WSQueries } from "../../query/WSQuery";
import { openIntercom } from "../../shared/utils/intercom";
import { useSetWSStore } from "../../store";
import { copyText } from "../../utils/copyText";
import { addBusinessDays, convertDateToEndDay } from "../../utils/dates";
import {
  InvoiceActivity,
  InvoiceDetailsActivity
} from "../components/InvoiceDetailsActivity/InvoiceDetailsActivity";
import { InvoiceDetailsCollaborators } from "../components/InvoiceDetailsCollaborators/InvoiceDetailsCollaborators";
import { InvoiceDetailsHeader } from "../components/InvoiceDetailsHeader/InvoiceDetailsHeader";
import { InvoicePayments } from "../components/InvoicePayments";
import { InvoiceRefunds } from "../components/InvoiceRefunds";
import { InvoiceTotals } from "../components/InvoiceTotals/InvoiceTotals";
import { usePayablesQuery } from "../components/NewPayablesTable/PayablesTable";
import { QuickbooksHistory } from "../components/QuickbooksHistory/QuickbooksHistory";
import { QuickbooksWrapper } from "../components/QuickbooksWrapper/QuickbooksWrapper";
import { calculateLineItemsTotal } from "../utils";

type Props = RouteComponentProps<
  {
    invoiceId: string;
    year: string;
    listName?: string;
  },
  {},
  { backPath?: string }
>;

export const InvoicesInvoiceDetails: React.FC<Props> = ({
  match,
  location,
  history
}) => {
  const invoiceId = match.params.invoiceId;
  const year = parseInt(
    match.params[UrlIdKey.Year]
  ) as keyof ICollaboratorSchema["form1099Balances"];
  const is1099Flow = !isNaN(year);
  const [detailsVisible, setDetailsVisible] = useState(true);
  const invoiceQuery = useInvoiceQuery(invoiceId);
  const userId = useUserId();
  const userQuery = useUserProfile(userId);
  const memberClientQuery = useMemberClientQuery(
    invoiceQuery.data?.memberClientId as string,
    {
      retry: false,
      enabled: !!invoiceQuery.data?.memberClientId
    }
  );
  const [sendInvoice, sendInvoiceMeta] = useSendInvoice();
  const [
    sendInvoiceReminder,
    sendInvoiceReminderMeta
  ] = useSendInvoiceReminder();
  const [downloadInvoicePdf, downloadInvoicePdfMeta] = useDownloadInvoicePdf();
  const collaboratorIds =
    invoiceQuery.data?.collaborators?.map(
      invoiceCollaborator => invoiceCollaborator.memberClientId
    ) || [];
  const payablesQuery = usePayablesQuery(
    {
      collaboratorIds
    },
    {
      enabled: collaboratorIds.length > 0
    }
  );
  const collaboratorsQuery = useCollaboratorsQuery();
  const memberQuery = useMemberProfile(userId);
  const payoutSettingsQuery = usePayoutSettings(userId);
  const setStore = useSetWSStore();
  const queryFeatureFlags = useFeatureFlags();

  return (
    <DetailsLayout
      onBack={() => {
        history.push({
          pathname: location.state?.backPath
            ? location.state.backPath
            : `/member/invoices${
                match.params.listName ? `/${match.params.listName}` : ""
              }`,
          search: location.search
        });
      }}
      title={
        invoiceQuery.isLoading
          ? ""
          : `Invoice #${invoiceQuery.data?.invoiceNumber || " Pending"}`
      }
    >
      <WSQueries
        queries={{
          invoiceQuery,
          memberClientQuery,
          payoutSettingsQuery,
          userQuery,
          memberQuery,
          collaboratorsQuery,
          queryFeatureFlags
        }}
      >
        {({
          invoiceQuery: { data: invoice },
          memberClientQuery: { data: memberClient },
          payoutSettingsQuery: { data: payoutSettings },
          userQuery: { data: user },
          memberQuery: { data: member },
          collaboratorsQuery: { data: collaborators },
          queryFeatureFlags: { data: featureFlags }
        }) => {
          const activities: InvoiceActivity[] = [];

          activities.push({
            title: "Invoice was created",
            description: toWSDateString(invoice.createdAt),
            date: invoice.createdAt
          });

          if (invoice.events.openedAt) {
            activities.push({
              title: `Sent to ${memberClient.emailTo}`,
              description: toWSDateString(invoice.events.openedAt),
              date: invoice.events.openedAt
            });
          }

          if (
            invoice.events.emailReceivedAt &&
            invoice.events.emailReceivedAt.length > 0
          ) {
            activities.push({
              title: `Received by ${memberClient.emailTo}`,
              description: toWSDateString(invoice.events.emailReceivedAt[0]),
              date: invoice.events.emailReceivedAt[0]
            });
          }

          // Work with viewed at only if it is before paidAt and depositedAt
          // Viewed event should never come after paid and deposited
          const allowedViewedAt = (
            invoice.events.emailViewedAt || []
          ).filter(date =>
            [
              invoice.events.paidAt,
              invoice.events.depositedAt,
              invoice.events.instantPayoutAt
            ].every(d => (d ? d > date : true))
          );

          if (allowedViewedAt.length > 0) {
            allowedViewedAt.sort((a, b) => b.getTime() - a.getTime());

            const firstViewedAt = allowedViewedAt[allowedViewedAt.length - 1];

            if (firstViewedAt) {
              activities.push({
                title: `Viewed by ${memberClient.emailTo}`,
                description: toWSDateString(firstViewedAt),
                date: firstViewedAt
              });
            }

            const secondViewedAt = firstViewedAt
              ? allowedViewedAt.find(
                  date =>
                    date.getTime() - firstViewedAt.getTime() > 60 * 60 * 1000
                )
              : undefined;

            if (secondViewedAt) {
              activities.push({
                title: `Viewed by ${memberClient.emailTo}`,
                description: toWSDateString(secondViewedAt),
                date: secondViewedAt
              });
            }
          }

          if (invoice.events.approvedAt) {
            activities.push({
              title: invoice.client?.payDate
                ? `Approved to be paid on ${toWSDateString(
                    invoice.client?.payDate,
                    "monthDate"
                  )}`
                : `Approved by ${memberClient.emailTo}`,
              description: toWSDateString(invoice.events.approvedAt),
              date: invoice.events.approvedAt
            });
          }

          if (invoice.events.unapprovedAt) {
            activities.push({
              title: invoice.client?.payDate
                ? `Unapproved on ${toWSDateString(
                    invoice.client?.payDate,
                    "monthDate"
                  )}`
                : `Unapproved by ${memberClient.emailTo}`,
              description: toWSDateString(invoice.events.unapprovedAt),
              date: invoice.events.unapprovedAt
            });
          }

          if (invoice.events.preApprovedAt) {
            activities.push({
              title: `Pre-approved by ${memberClient.emailTo}`,
              description: toWSDateString(invoice.events.preApprovedAt),
              date: invoice.events.preApprovedAt
            });
          }

          if (invoice.events.sentDueIn3DaysAt) {
            activities.push({
              title: `Reminder sent to ${memberClient.emailTo}`,
              description: toWSDateString(invoice.events.sentDueIn3DaysAt),
              date: invoice.events.sentDueIn3DaysAt
            });
          }

          if (invoice.events.sentDueTodayAt) {
            activities.push({
              title: `Reminder sent to ${memberClient.emailTo}`,
              description: toWSDateString(invoice.events.sentDueTodayAt),
              date: invoice.events.sentDueTodayAt
            });
          }

          if (invoice.events.sentDue3DaysAgoAt) {
            activities.push({
              title: `Reminder sent to ${memberClient.emailTo}`,
              description: toWSDateString(invoice.events.sentDue3DaysAgoAt),
              date: invoice.events.sentDue3DaysAgoAt
            });
          }

          if (invoice.events.sentDue7DaysAgoAt) {
            activities.push({
              title: `Reminder sent to ${memberClient.emailTo}`,
              description: toWSDateString(invoice.events.sentDue7DaysAgoAt),
              date: invoice.events.sentDue7DaysAgoAt
            });
          }

          if (invoice.events.paymentInTransitAt) {
            activities.push({
              title: `Payment in transit on ${toWSDateString(
                invoice.events.paymentInTransitAt
              )}`,
              description: "Waiting on client's wire transfer to arrive",
              date: invoice.events.paymentInTransitAt
            });
          }

          if (invoice.events.paidAt) {
            activities.push(
              invoice.events.instantPayoutAt
                ? {
                    title: "Invoice paid",
                    description: toWSDateString(invoice.events.paidAt),
                    date: invoice.events.paidAt
                  }
                : {
                    title: `Paid on ${toWSDateString(invoice.events.paidAt)}`,
                    description: invoice.events.estimatedDepositAt
                      ? `Expected deposit on ${toWSDateString(
                          invoice.events.estimatedDepositAt,
                          "monthDayYear"
                        )}`
                      : "Invoice was paid outside of Wingspan",
                    date: invoice.events.paidAt
                  }
            );
          }

          if (invoice.events.depositedAt && !invoice.events.instantPayoutAt) {
            activities.push({
              title: "Payout",
              description: `On ${toWSDateString(
                invoice.events.depositedAt,
                "monthDate"
              )}, arrives by ${toWSDateString(
                addBusinessDays(invoice.events.depositedAt, 1),
                "monthDate"
              )}`,
              date: convertDateToEndDay(invoice.events.depositedAt)
            });
          }

          if (invoice.events.instantPayoutAt) {
            activities.push({
              title: "Deposited instantly",
              description: toWSDateString(invoice.events.instantPayoutAt),
              date: invoice.events.instantPayoutAt
            });
          }

          if (invoice.events.memberDisputedAt) {
            activities.push({
              title: `Invoice disputed`,
              description: `Dispute reason: ${invoice.member.comment}`,
              date: invoice.events.memberDisputedAt
            });
          }

          if (invoice.events.clientResolvedDisputeAt) {
            activities.push({
              title: "Dispute resolved",
              description: `Client resolved dispute on ${(toWSDateString(
                invoice.events.clientResolvedDisputeAt
              ),
              "monthDate")}`,
              date: invoice.events.clientResolvedDisputeAt
            });
          }

          if (invoice.events.memberAcceptedAt) {
            activities.push({
              title: `Invoice accepted`,
              description: `Accepted by you on ${toWSDateString(
                invoice.events.memberAcceptedAt,
                "monthDate"
              )}`,
              date: invoice.events.memberAcceptedAt
            });
          }

          if (invoice.events.clientDeclinedAt) {
            activities.push({
              title: `Rejected by ${memberClient.client.user.email}`,
              description: `Reject reason: ${invoice.client.comment}`,
              date: invoice.events.clientDeclinedAt
            });
          }

          if (invoice.events.memberResubmittedAt) {
            activities.push({
              title: `Resubmitted by ${user.email}`,
              description: `You resubmitted at ${toWSDateString(
                invoice.events.memberResubmittedAt
              )}`,
              date: invoice.events.memberResubmittedAt
            });
          }

          activities.sort((a, b) => {
            if (a.date > b.date) return -1;
            if (a.date < b.date) return 1;
            return 0;
          });

          invoice.collaborators?.forEach(invoiceCollaborator => {
            const payable = payablesQuery.data?.data.find(
              p => p.payableId === invoiceCollaborator.invoiceId
            );
            const collaborator = collaborators.find(
              c => c.collaboratorId === invoiceCollaborator.memberClientId
            );

            if (payable && collaborator) {
              if (payable.events.depositedAt) {
                activities.push({
                  title: `${getCollaboratorName(
                    collaborator
                  )} received ${toWSMoneyString(invoiceCollaborator.amount)}`,
                  description: toWSDateString(payable.events.depositedAt),
                  date: payable.events.depositedAt
                });
              }
            }
          });

          const editButtonProps = {
            children: "Edit",
            onClick: () => {
              setStore({ invoiceFormBackPath: location.pathname });
              history.push(`/member/invoices/${invoice.invoiceId}/edit`);
            }
          };

          const clientName = getClientName(memberClient);

          return (
            <>
              <Card mb="XL">
                <WSText.Heading4 mb="M">
                  {getClientCompany(memberClient)}
                </WSText.Heading4>
                {invoice.events.depositedAt ||
                invoice.events.instantPayoutAt ? (
                  <InvoiceDetailsHeader
                    title="You got paid"
                    description={
                      <WSFlexBox.CenterY>
                        {invoice.payoutDestinations &&
                        invoice.payoutDestinations.length > 0
                          ? `Funds deposited to ${invoice.payoutDestinations
                              .map(getInvoicePayoutDestinationDescription)
                              .join(" and ")}`
                          : "Funds have been deposited"}
                      </WSFlexBox.CenterY>
                    }
                    status="Deposited"
                    buttonProps={{
                      children: "Download PDF",
                      onClick: () => {
                        downloadInvoicePdf({
                          invoiceId: invoice.invoiceId,
                          status: invoice.status
                        });
                      },
                      loading: downloadInvoicePdfMeta.isLoading
                    }}
                    invoice={invoice}
                    memberClient={memberClient}
                  />
                ) : invoice.events.paidAt ? (
                  <InvoiceDetailsHeader
                    title={
                      invoice.amountDetails
                        ? "Your money is on its way!"
                        : "Invoice was paid outside of Wingspan"
                    }
                    description={`Paid ${toWSDateString(
                      invoice.events.paidAt,
                      "monthDayYear"
                    )}`}
                    status="Paid"
                    buttonProps={
                      getIsInstantPayoutAllowed(invoice, payoutSettings, member)
                        ? {
                            children: "Request instant payout",
                            onClick: () => {
                              history.push(
                                `${location.pathname}/instant-deposit`
                              );
                            }
                          }
                        : {
                            children: "Download PDF",
                            onClick: () => {
                              downloadInvoicePdf({
                                invoiceId: invoice.invoiceId,
                                status: invoice.status
                              });
                            },
                            loading: downloadInvoicePdfMeta.isLoading
                          }
                    }
                    invoice={invoice}
                    memberClient={memberClient}
                  />
                ) : invoice.status === InvoiceStatus.PaymentInTransit ? (
                  <InvoiceDetailsHeader
                    title="Waiting on client's wire transfer to arrive"
                    description="Your money is on its way!"
                    status="Paid"
                    buttonProps={{
                      children: "Copy invoice link",
                      onClick: () => {
                        if (invoice.attachments?.invoiceLink) {
                          copyText(
                            invoice.attachments?.invoiceLink,
                            "Invoice link copied to clipboard."
                          );
                        }
                      }
                    }}
                    invoice={invoice}
                    memberClient={memberClient}
                  />
                ) : invoice.events.emailViewedAt &&
                  invoice.events.emailViewedAt.length > 0 &&
                  invoice.status === InvoiceStatus.Overdue ? (
                  <InvoiceDetailsHeader
                    title="Invoice payment is overdue"
                    description="We recommend personally following up"
                    status="Viewed"
                    buttonProps={{
                      children: "Email client",
                      onClick: () => {
                        sendInvoiceReminder({ invoice });
                      },
                      loading: sendInvoiceReminderMeta.isLoading
                    }}
                    icon="info-circle"
                    iconColor="red400"
                    invoice={invoice}
                    memberClient={memberClient}
                  />
                ) : invoice.events.emailViewedAt &&
                  invoice.events.emailViewedAt.length > 0 ? (
                  <InvoiceDetailsHeader
                    title="Your invoice has been viewed"
                    description={`Seen by ${memberClient.emailTo}`}
                    status="Viewed"
                    buttonProps={{
                      children: "Copy invoice link",
                      onClick: () => {
                        if (invoice.attachments?.invoiceLink) {
                          copyText(
                            invoice.attachments?.invoiceLink,
                            "Invoice link copied to clipboard."
                          );
                        }
                      }
                    }}
                    invoice={invoice}
                    memberClient={memberClient}
                  />
                ) : invoice.status === InvoiceStatus.Overdue ? (
                  <InvoiceDetailsHeader
                    title="Invoice payment is overdue"
                    description="We recommend personally following up"
                    status="Sent"
                    buttonProps={{
                      children: "Email client",
                      onClick: () => {
                        sendInvoiceReminder({ invoice });
                      },
                      loading: sendInvoiceReminderMeta.isLoading
                    }}
                    icon="info-circle"
                    iconColor="red400"
                    invoice={invoice}
                    memberClient={memberClient}
                  />
                ) : invoice.status === InvoiceStatus.Open ? (
                  <InvoiceDetailsHeader
                    title="Your invoice has been sent"
                    description={`Sent to ${memberClient.emailTo}`}
                    status="Sent"
                    buttonProps={{
                      children: "Copy invoice link",
                      onClick: () => {
                        if (invoice.attachments?.invoiceLink) {
                          copyText(
                            invoice.attachments?.invoiceLink,
                            "Invoice link copied to clipboard."
                          );
                        }
                      }
                    }}
                    invoice={invoice}
                    memberClient={memberClient}
                  />
                ) : invoice.status === InvoiceStatus.Pending ? (
                  <InvoiceDetailsHeader
                    title="This is pending"
                    description={
                      <>
                        <WSText>Will be sent to {memberClient.emailTo}</WSText>

                        <WSButton.Link my="M" onClick={openIntercom}>
                          Ask about pending invoices
                        </WSButton.Link>
                      </>
                    }
                    buttonProps={editButtonProps}
                    invoice={invoice}
                    memberClient={memberClient}
                  />
                ) : (
                  <InvoiceDetailsHeader
                    title="This is a draft"
                    description="Send now or schedule for later"
                    buttonProps={
                      calculateLineItemsTotal(invoice.lineItems) > 0
                        ? {
                            children: "Send now",
                            onClick: () => {
                              sendInvoice({
                                invoiceId
                              });
                            },
                            loading: sendInvoiceMeta.isLoading
                          }
                        : editButtonProps
                    }
                    invoice={invoice}
                    memberClient={memberClient}
                  />
                )}
              </Card>

              <Card mb="XL">
                <WSFlexBox wrap="nowrap" justify="space-between">
                  <WSButton.Link
                    onClick={() => {
                      setDetailsVisible(!detailsVisible);
                    }}
                    rightIcon={detailsVisible ? "caret-up" : "caret-down"}
                  >
                    {detailsVisible ? "Hide" : "Show"} invoice details
                  </WSButton.Link>
                  <WSText.Heading5>
                    {toWSMoneyString(
                      calculateLineItemsTotal(invoice.lineItems)
                    )}
                  </WSText.Heading5>
                </WSFlexBox>
                {detailsVisible && (
                  <WSElement mt="XL">
                    <WSElement mb="XL">
                      <WSText.ParagraphSm mb="XS" color="gray500">
                        Bill to
                      </WSText.ParagraphSm>
                      {clientName !== memberClient.emailTo && (
                        <WSText>{clientName}</WSText>
                      )}
                      <WSText>
                        {[
                          memberClient.emailTo,
                          ...(memberClient.emailCC || [])
                        ].join(", ")}
                      </WSText>
                    </WSElement>
                    {invoice.metadata?.purchaseOrderNumber ? (
                      <WSElement mb="XL">
                        <WSText.ParagraphSm mb="XS" color="gray500">
                          PO Number
                        </WSText.ParagraphSm>
                        <WSText>{invoice.metadata?.purchaseOrderNumber}</WSText>
                      </WSElement>
                    ) : null}
                    <WSElement mb="XL">
                      <WSText.ParagraphSm mb="XS" color="gray500">
                        Due date
                      </WSText.ParagraphSm>
                      <WSText>
                        {toWSDateString(invoice.dueDate, "monthDayYear")}
                      </WSText>
                    </WSElement>

                    {invoice.invoiceNotes && (
                      <WSElement mb="XL">
                        <WSText.ParagraphSm mb="XS" color="gray500">
                          Invoice notes
                        </WSText.ParagraphSm>
                        <WSText>{invoice.invoiceNotes}</WSText>
                      </WSElement>
                    )}

                    {invoice.labels &&
                      Object.keys(invoice.labels)
                        .filter(key => key[0] === key[0].toUpperCase())
                        .map(key => (
                          <WSElement mb="XL" key={key}>
                            <WSText.ParagraphSm mb="XS" color="gray500">
                              {key.replace(/([a-z])([A-Z])/g, "$1 $2")}
                            </WSText.ParagraphSm>
                            <WSText>
                              {(invoice.labels[key] as any) instanceof Date
                                ? toWSDateString(
                                    invoice.dueDate,
                                    "monthDayYear"
                                  )
                                : invoice.labels[key]}
                            </WSText>
                          </WSElement>
                        ))}

                    {invoice.paymentInfo?.meansType && (
                      <WSElement mb="XL">
                        <WSText.ParagraphSm mb="XS" color="gray500">
                          Paid with
                        </WSText.ParagraphSm>
                        {invoice.paymentInfo.meansType ===
                        TransferType.Account ? (
                          <WSText>
                            ACH transfer: {invoice.paymentInfo.meansDescription}
                          </WSText>
                        ) : (
                          <>
                            <WSText>Credit card</WSText>
                            <CreditCardPreview
                              paymentMethod={{
                                last4: invoice.paymentInfo.meansLast4,
                                cardBrand: invoice.paymentInfo.cardBrand
                              }}
                            />
                          </>
                        )}
                      </WSElement>
                    )}

                    {invoice.events.instantPayoutAt ? (
                      <WSElement mb="XL">
                        <WSText.ParagraphSm mb="XS" color="gray500">
                          Instant payout date
                        </WSText.ParagraphSm>
                        <WSText>
                          {toWSDateString(
                            invoice.events.instantPayoutAt,
                            "monthDayYear"
                          )}
                        </WSText>
                      </WSElement>
                    ) : invoice.events.depositedAt ? (
                      <WSElement mb="XL">
                        <WSText.ParagraphSm mb="XS" color="gray500">
                          Deposited date
                        </WSText.ParagraphSm>
                        <WSText>
                          {toWSDateString(
                            invoice.events.depositedAt,
                            "monthDayYear"
                          )}
                        </WSText>
                      </WSElement>
                    ) : invoice.member.payoutPreferences ===
                      PayoutPreferences.Instant ? (
                      <WSElement mb="XL">
                        <WSText.ParagraphSm mb="XS" color="gray500">
                          Estimated deposit date
                        </WSText.ParagraphSm>
                        <WSText>
                          {toWSDateString(new Date(), "monthDate")} (instant
                          deposit)
                        </WSText>
                      </WSElement>
                    ) : invoice.events.estimatedDepositAt ? (
                      <WSElement mb="XL">
                        <WSText.ParagraphSm mb="XS" color="gray500">
                          Estimated deposit date
                        </WSText.ParagraphSm>
                        <WSText>
                          {toWSDateString(
                            invoice.events.estimatedDepositAt,
                            "monthDate"
                          )}
                        </WSText>
                      </WSElement>
                    ) : null}

                    <InvoiceTotals
                      invoiceId={invoiceId}
                      is1099Flow={is1099Flow}
                    />

                    {invoice.lateFeeHandling &&
                      (!!invoice.lateFeeHandling.lateFeePercentage ||
                        !!invoice.lateFeeHandling.lateFeeAmount) && (
                        <WSElement mt="XL">
                          <WSText.ParagraphSm mb="XS" color="gray500">
                            Late fee
                          </WSText.ParagraphSm>
                          <WSText>
                            {invoice.lateFeeHandling.lateFeePercentage
                              ? `${invoice.lateFeeHandling.lateFeePercentage}%`
                              : toWSMoneyString(
                                  invoice.lateFeeHandling.lateFeeAmount
                                )}{" "}
                            every {invoice.lateFeeHandling.frequency.every}{" "}
                            {invoice.lateFeeHandling.frequency.interval} overdue
                          </WSText>
                        </WSElement>
                      )}

                    {invoice.attachments?.customAttachmentIds && (
                      <WSElement mt="XL">
                        {invoice.attachments?.customAttachmentIds.map(id => (
                          <PublicAttachmentDownloadLink id={id} mb="M" />
                        ))}
                      </WSElement>
                    )}

                    {invoice.collaborators && invoice.collaborators.length > 0 && (
                      <WSElement mt="3XL">
                        <WSText.Heading5 mb="M">Collaborators</WSText.Heading5>
                        <InvoiceDetailsCollaborators
                          invoiceCollaborators={invoice.collaborators}
                        />
                      </WSElement>
                    )}
                  </WSElement>
                )}
              </Card>

              {featureFlags.invoicePaymentsDetails &&
                invoice.payments &&
                invoice.payments.length > 0 && (
                  <Card mb="XL">
                    <WSText.Heading5 mb="XL">Payments</WSText.Heading5>
                    <InvoicePayments invoice={invoice} />
                  </Card>
                )}

              {featureFlags.refundsPane &&
                invoice.refundDestinations &&
                invoice.refundDestinations.length > 0 && (
                  <Card mb="XL">
                    <WSText.Heading5 mb="XL">Refunds</WSText.Heading5>
                    <InvoiceRefunds invoice={invoice} />
                  </Card>
                )}

              <Card>
                <WSText.Heading5 mb="XL">All activity</WSText.Heading5>
                <InvoiceDetailsActivity activities={activities} />
              </Card>

              <QuickbooksWrapper>
                <QuickbooksHistory
                  entityId={invoice.invoiceId}
                  Wrapper={Card}
                  mt="XL"
                />
              </QuickbooksWrapper>
            </>
          );
        }}
      </WSQueries>
    </DetailsLayout>
  );
};
