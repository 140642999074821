import {
  useWSSnackbar,
  WSAlert,
  WSButton,
  WSButtons,
  WSCentered,
  WSContainer,
  WSControlGroup,
  WSDivider,
  WSDropFileInput,
  WSElement,
  WSFlexBox,
  WSForm,
  WSGrid,
  WSIcon,
  WSInputText,
  WSInputTextarea,
  WSLayout,
  WSList,
  WSSelect,
  WSText
} from "@wingspanhq/fe-component-library";
import { useHistory, useLocation } from "react-router-dom";
import * as Yup from "yup";
import { ENGAGEMENT_REQUIREMENTS_SETTINGS_ROOT_PATH } from "../..";
import { WSErrorMessage } from "../../../../components/WSErrorMessage/WSErrorMessage";
import { ErrorContextKey } from "../../../../services/platform";

import styles from "./styles.module.scss";
import { RequirementType } from "@wingspanhq/payments/dist/interfaces";
import React, { useState } from "react";
import { useMemberPrivateFileCreate } from "../../../../query/files/mutations";
import { useCreateSignedDocumentTemplate } from "../../../../query/signedDocuments/mutations/useCreateSignedDocumentTemplate";
import { SignerRole } from "@wingspanhq/signed-documents/dist/lib/interfaces";
import { useUserId } from "../../../../query/hooks/helpers";
import { helloSignClient, helloSignPromise } from "../../../../utils/helloSign";
import { useConfirmCreateRequirementModal } from "./useConfirmCreateRequirementModal";
import { openInNewTab } from "../../../../utils/openInNewTab";
import { WS_LINKS } from "../../../../types/wsLinks";

export interface CreateRequirementFormValues {
  name: string;
  type: RequirementType;
  description?: string;

  eSignatureFile?: File;
  requiredSignatures: "collaborator" | "mutual";
}

const REQUIREMENT_TYPE_OPTIONS = [
  {
    value: RequirementType.Signature, // TODO: replace it with new enum if created new one
    label: "e-Signature"
  }
];

export const RouteCreateRequirement: React.FC = () => {
  const history = useHistory();
  const { openSnackbar } = useWSSnackbar();
  const location = useLocation<{ engagementId: string }>();
  const userId = useUserId();
  const [fileToUpload, setFileToUpload] = useState<File>();
  const engagementId = location.state?.engagementId;

  const [uploadFile, uploadFileMeta] = useMemberPrivateFileCreate({
    hidden: true
    // TODO: do we need to set tags & viewerIds here?
  });
  const [
    createSignedDocumentTemplate,
    createSignedDocumentTemplateMeta
  ] = useCreateSignedDocumentTemplate();
  const confirmCreateRequirementModal = useConfirmCreateRequirementModal();

  const onSubmit = async (values: CreateRequirementFormValues) => {
    if (!fileToUpload) {
      return;
    }

    const fileId = await new Promise<string>(async (resolve, reject) => {
      const data = new FormData();
      data.append("file", fileToUpload);
      await uploadFile(data, {
        onSuccess: response => {
          resolve(response[0].fileId);
        },
        onError: error => {
          openSnackbar({
            message: "Failed to upload file",
            type: "error"
          });
          reject(error);
        }
      });
    });

    if (fileId) {
      // create a template
      const template = await createSignedDocumentTemplate({
        userId,
        title: values.name,
        fileId: fileId,
        roles:
          values.requiredSignatures === "collaborator"
            ? [SignerRole.Payee]
            : [SignerRole.Payee, SignerRole.Payer]
      });

      // open hello sign modal
      helloSignClient.open(template?.editUrl as string);

      const status = await helloSignPromise();

      if (status === "closed") {
        // open confirmation modal to create requirement
        confirmCreateRequirementModal.open({
          engagementId,
          templateId: template?.signedDocumentTemplateId as string,
          formValues: values
        });
      }
    }
  };

  return (
    <WSLayout
      headerCenter={
        <WSText.ParagraphSm weight="medium">
          Create requirement
        </WSText.ParagraphSm>
      }
      onClose={() => {
        history.replace(ENGAGEMENT_REQUIREMENTS_SETTINGS_ROOT_PATH);
      }}
    >
      <WSContainer verticalPadding>
        <WSCentered span={{ s: "10", m: "8", l: "6" }}>
          <WSText.Paragraph weight="medium" mb="S">
            Requirement information
          </WSText.Paragraph>

          <WSForm<CreateRequirementFormValues>
            defaultValues={{
              requiredSignatures: "collaborator",
              type: REQUIREMENT_TYPE_OPTIONS[0].value
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string().required("Required"),
              type: Yup.string()
                .required("Required")
                .nullable()
            })}
            onSubmit={onSubmit}
          >
            <WSGrid gutter={{ m: "2XL" }}>
              <WSGrid.Item span={{ m: "6", s: "12", xs: "12" }}>
                <WSForm.Field
                  name="name"
                  label="Name"
                  placeholder="Requirement name"
                  component={WSInputText}
                  componentProps={{
                    required: true
                  }}
                />
              </WSGrid.Item>

              <WSGrid.Item span={{ m: "6", s: "12", xs: "12" }}>
                <WSForm.Field
                  name="type"
                  label="Type"
                  placeholder="Requirement type"
                  component={WSSelect}
                  componentProps={{
                    required: true,
                    options: REQUIREMENT_TYPE_OPTIONS
                  }}
                />
              </WSGrid.Item>

              <WSGrid.Item span={{ m: "12" }}>
                <WSForm.Field
                  name="description"
                  label="Description"
                  placeholder="Requirement description"
                  component={WSInputTextarea}
                />
              </WSGrid.Item>

              <WSErrorMessage
                mt="M"
                contextKey={ErrorContextKey.Other}
                error={uploadFileMeta.error}
              />
            </WSGrid>

            <WSDivider my="XL" />

            <WSList gap="XL">
              <WSForm.Value name="type">
                {type =>
                  type === RequirementType.Signature && (
                    <WSAlert
                      theme="info"
                      title="About e-Signature requirements"
                    >
                      Contractor(s) assigned with this requirement will be asked
                      to e-sign the document uploaded here.
                    </WSAlert>
                  )
                }
              </WSForm.Value>

              {fileToUpload ? (
                <>
                  <WSElement p="XL" className={styles.selectedFile}>
                    <WSFlexBox.CenterY wrap="nowrap">
                      <WSText className={styles.selectedFileName} singleLine>
                        {fileToUpload.name}
                      </WSText>
                      <WSFlexBox>
                        <WSIcon
                          block
                          color="gray500"
                          size="M"
                          mx="M"
                          name="exit"
                          onClick={() => {
                            setFileToUpload(undefined);
                            uploadFileMeta.reset();
                          }}
                        />
                      </WSFlexBox>
                    </WSFlexBox.CenterY>
                  </WSElement>
                  <WSForm.Field
                    name="requiredSignatures"
                    label="Required signatures"
                    component={WSControlGroup}
                    componentProps={{
                      required: true,
                      type: "radio",
                      options: [
                        {
                          value: "collaborator",
                          label: "Contractor signature required only"
                        },
                        {
                          value: "mutual",
                          label:
                            "Both contractor and company signatures are required"
                        }
                      ]
                    }}
                  />
                </>
              ) : (
                <WSForm.Value name="type">
                  {value =>
                    value === RequirementType.Signature && (
                      <>
                        <WSElement>
                          <WSText.ParagraphSm mb="S" color="gray500">
                            Upload e-Signature file *
                          </WSText.ParagraphSm>
                          <WSDropFileInput
                            name="eSignatureFile"
                            buttonText="Browse files"
                            buttonProps={{
                              type: "button"
                            }}
                            accept=".pdf"
                            onDropAccepted={(accepted: File[]) => {
                              setFileToUpload(accepted[0]);
                            }}
                          />
                        </WSElement>
                      </>
                    )
                  }
                </WSForm.Value>
              )}
            </WSList>
            <WSDivider my="XL" />

            <WSForm.Value name="type">
              {type => (
                <WSButtons mb="XL">
                  <WSButton.Tertiary
                    type="button"
                    className={styles.formBtn}
                    size="M"
                    onClick={() => {
                      history.replace(
                        ENGAGEMENT_REQUIREMENTS_SETTINGS_ROOT_PATH
                      );
                    }}
                  >
                    Cancel
                  </WSButton.Tertiary>

                  <WSButton.Primary
                    type="submit"
                    className={styles.formBtn}
                    size="M"
                    loading={
                      uploadFileMeta.isLoading ||
                      createSignedDocumentTemplateMeta.isLoading
                    }
                    disabled={
                      type === RequirementType.Signature && !fileToUpload
                    }
                  >
                    Continue
                  </WSButton.Primary>
                </WSButtons>
              )}
            </WSForm.Value>
          </WSForm>
          <WSText.ParagraphXs weight="book" color="gray400" my="XL">
            Wingspan can auto-fill known info like names, emails, custom fields
            which you can configure in the next step.
          </WSText.ParagraphXs>
          <WSButton.Link
            rightIcon="chevron-right"
            type="button"
            size="S"
            onClick={() => openInNewTab(WS_LINKS.eligibilityRequirements)}
          >
            Learn more about requirements
          </WSButton.Link>
        </WSCentered>
      </WSContainer>
    </WSLayout>
  );
};
