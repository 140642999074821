import React, { useMemo } from "react";
import { WSQueries } from "../../../../query/WSQuery";
import {
  toWSDateString,
  WSEmptyState,
  WSFilter,
  WSFilters,
  WSInfiniteScroll
} from "@wingspanhq/fe-component-library";
import { useHistory } from "react-router-dom";
import { useUrlQueryFilters } from "../../../../utils/router";
import { PayeeRowsTable } from "../../components/PayeeRowsTable";
import { PayerPayeeEngagementSearchableStatus } from "../../../../services/search";
import { usePayeeRowsQuery } from "../../../../query/search/payee/queries/usePayeeRowsQuery";
import { usePayeeRowsSummaryQuery } from "../../../../query/search/payee/queries/usePayeeRowsSummaryQuery";
import {
  filtersValuesToQueryParams,
  queryParamsToFiltersValues
} from "../../../../utils/filters";
import { useBulkCollaboratorBatchesQuery } from "../../../BulkImporter/query/bulkCollaborator/queries";
import { BulkStatus } from "@wingspanhq/payments/dist/interfaces/bulkUpload";
import { getUploadedFilename } from "../../../BulkImporter/utils/getUploadedFilename";
import { PaymentRequirementStrategy } from "@wingspanhq/payments/dist/interfaces/payerPayee";
import { useEngagementsListAllQuery } from "../../../../query/engagements/queries/useEngagementsListAllQuery";
import {
  PayeeTaxInfoPermission,
  RedactedPayeeRowsQuery,
  RedactedPayeeRowsQueryFilter
} from "../../../../query/search/payee/queries/utils";
import { useBrowserPageTitle } from "../../../../shared/hooks/useBrowserPageTitle";
import { useCreateContractorModal } from "../../components/CreateContractorModal/useCreateContractorModal";
import { buildPayeeDetailsPath } from "../../utils";
import { useCustomization } from "../../../customization";
import { BulkResource } from "../../../BulkImporter/types";

type FiltersValues = {
  searchableStatus?:
    | PayerPayeeEngagementSearchableStatus
    | PayerPayeeEngagementSearchableStatus[];
  "labels.bulkBatchId"?: string | string[];
};

const DEFAULT_FILTERS: RedactedPayeeRowsQueryFilter = {
  searchableStatus: {
    "!=": PayerPayeeEngagementSearchableStatus.Inactive
  }
};

export const RoutePayees: React.FC = () => {
  const { terminology } = useCustomization();

  useBrowserPageTitle(terminology().sendPaymentsContractor);
  const history = useHistory();
  const { filters, setFilters, changedFilters } = useUrlQueryFilters<
    RedactedPayeeRowsQuery
  >({
    filter: DEFAULT_FILTERS,
    sort: {
      updatedAt: "desc"
    }
  });
  const modalCreateContractor = useCreateContractorModal();

  const queryPayeeGeneralSummary = usePayeeRowsSummaryQuery({
    filter: {
      searchableStatus: {
        "!=": PayerPayeeEngagementSearchableStatus.Inactive
      }
    }
  });

  const queryPayeeRows = usePayeeRowsQuery(filters);
  const queryPayeeRowsSummary = usePayeeRowsSummaryQuery(filters);
  const queryEngagementList = useEngagementsListAllQuery({
    filter: {
      isDefault: false
    }
  });
  const queryBulkPayableBatches = useBulkCollaboratorBatchesQuery();

  const values = useMemo(() => {
    return queryParamsToFiltersValues(filters.filter || {});
  }, [filters.filter]);

  return (
    <WSQueries
      queries={{
        queryPayeeRows,
        queryPayeeRowsSummary,
        queryEngagementList,
        queryBulkPayableBatches,
        queryPayeeGeneralSummary
      }}
    >
      {({
        queryPayeeRowsData,
        queryPayeeRowsSummaryData,
        queryEngagementListData: engagements,
        queryBulkPayableBatchesData,
        queryPayeeGeneralSummaryData
      }) => {
        const engagementOptions = engagements.map(engagement => ({
          label: engagement.name,
          name: engagement.engagementId
        }));

        const batchOptions = queryBulkPayableBatchesData
          .filter(bulkBatch =>
            [BulkStatus.Complete, BulkStatus.Failed].includes(bulkBatch.status)
          )
          .map(bulkBatch => ({
            label:
              getUploadedFilename(bulkBatch, BulkResource.Collaborator) +
              ` (created ${toWSDateString(bulkBatch.createdAt)})`,
            name: bulkBatch.bulkCollaboratorBatchId
          }));

        return (
          <>
            {queryPayeeGeneralSummaryData.listSize > 0 && (
              <WSFilters<FiltersValues>
                my="L"
                values={values}
                onFilter={newValues => {
                  setFilters({
                    filter: filtersValuesToQueryParams(newValues)
                  });
                }}
                primaryFilters={[
                  ...(engagementOptions.length
                    ? [
                        {
                          title: "Engagements",
                          name: "engagements.engagementId",
                          type: "checkbox",
                          options: engagementOptions
                        } as WSFilter
                      ]
                    : []),
                  {
                    title: "Status",
                    name: "searchableStatus",
                    type: "checkbox",
                    options: [
                      {
                        label: "Not signed up",
                        name: PayerPayeeEngagementSearchableStatus.NotSignedUp
                      },
                      {
                        label: "Not eligible for payment",
                        name: PayerPayeeEngagementSearchableStatus.NotEligible
                      },
                      {
                        label: "Eligible for payment",
                        name: PayerPayeeEngagementSearchableStatus.Eligible
                      }
                      // {
                      //   label: "Archived",
                      //   name: PayerPayeeEngagementSearchableStatus.Inactive
                      // }
                    ]
                  },
                  {
                    title: "Autopay",
                    name: "payerOwnedData.autoPayStrategy",
                    type: "checkbox",
                    titleInLabel: true,
                    options: [
                      {
                        label: "On",
                        name: PaymentRequirementStrategy.All
                      },
                      {
                        label: "Off",
                        name: PaymentRequirementStrategy.None
                      }
                    ]
                  },
                  {
                    title: "Tax info",
                    name: "payeeOwnedData.shareTaxDocument",
                    type: "checkbox",
                    titleInLabel: true,
                    options: [
                      {
                        label: "Not shared",
                        name: PayeeTaxInfoPermission.NotShared
                      },
                      {
                        label: "Shared",
                        name: PayeeTaxInfoPermission.Shared
                      }
                    ]
                  },
                  ...(batchOptions.length
                    ? [
                        {
                          title: "Bulk import batch",
                          name: "labels.bulkBatchId",
                          type: "checkbox",
                          options: batchOptions
                        } as WSFilter
                      ]
                    : [])
                ]}
                info={{
                  count: queryPayeeRowsSummaryData.listSize
                }}
                quickFilters={[
                  {
                    icon: true,
                    theme: "warning",
                    label: "Not signed up",
                    shortLabel: "Not signed up",
                    preset: {
                      searchableStatus: [
                        PayerPayeeEngagementSearchableStatus.NotSignedUp
                      ]
                    }
                  },
                  {
                    icon: true,
                    theme: "warning",
                    label: "Not eligible for payment",
                    shortLabel: "Not eligible",
                    preset: {
                      searchableStatus: [
                        PayerPayeeEngagementSearchableStatus.NotEligible
                      ]
                    }
                  },
                  {
                    icon: true,
                    theme: "success",
                    label: "Eligible for payment",
                    shortLabel: "Eligible",
                    preset: {
                      searchableStatus: [
                        PayerPayeeEngagementSearchableStatus.Eligible
                      ]
                    }
                  }
                ]}
              />
            )}
            {Object.keys(changedFilters).length &&
            queryPayeeRowsData.length === 0 ? (
              <WSEmptyState
                py="3XL"
                fullWidth
                hasBorder
                bgColor="gray"
                type="search"
                title="No results found"
                description="Try adjusting your search or filter to find what you’re looking for."
              />
            ) : queryPayeeRowsData.length === 0 ? (
              <WSEmptyState
                py="3XL"
                mt="L"
                hasBorder
                fullWidth
                type="people"
                title="No contractors yet"
                bgColor="gray"
                description="Once a contractor is added, they will appear here."
                buttons={[
                  {
                    label: "Add contractor",
                    kind: "Secondary",
                    onClick: async () => {
                      const result = await modalCreateContractor.open();
                      if (result) {
                        history.push(buildPayeeDetailsPath(result.payeeId));
                      }
                    }
                  },
                  {
                    label: "Bulk upload contractors",
                    kind: "Tertiary",
                    onClick: async () => {
                      history.push(
                        "/member/invoices/contacts/collaborators/bulk-create/info"
                      );
                    }
                  }
                ]}
              />
            ) : (
              <WSInfiniteScroll
                mt="M"
                onLoad={() => {
                  queryPayeeRows.fetchMore();
                }}
                loadMore={queryPayeeRowsData.length > 0}
                endOfList={!queryPayeeRows.canFetchMore}
                loading={!!queryPayeeRows.isFetchingMore}
              >
                <PayeeRowsTable payees={queryPayeeRowsData} filters={filters} />
              </WSInfiniteScroll>
            )}
          </>
        );
      }}
    </WSQueries>
  );
};
