import { FormData } from ".";
import { DEFAULT_PAYER_INDUSTRY } from "../../../CIP/helpers/industry";

export const emptyValues: FormData = {
  legalBusinessName: "",
  dba: "",
  taxClassification: null,
  taxId: "",
  industry: DEFAULT_PAYER_INDUSTRY,
  email: "",
  usePersonalEmail: false,
  phone: {
    countryCode: "1",
    phoneNumber: ""
  },
  website: "",
  noWebsite: false,
  numberOfEmployees: "None",
  address: {
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    postalCode: ""
  },
  canadaQuebecEnterpriseNumber: "",
  stateOfIncorporation: null,
  yearOfIncorporation: null
};
