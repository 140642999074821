import { useHistory } from "react-router-dom";
import { FormActivateTaxWithholdings } from "../../Onboarding/components/FormActivateTaxWithholdings";
import { Layout } from "../../Onboarding/components/Layout";

export const RouteActivateTaxWithholding = () => {
  const history = useHistory();
  return (
    <Layout title="Activate Tax Withholding">
      <FormActivateTaxWithholdings
        onBack={() => {
          history.push("/member/taxes");
        }}
        onContinue={() => {
          history.push("/member/taxes/onboarding/filing-status");
        }}
      />
    </Layout>
  );
};
